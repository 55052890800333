import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, throwError, of } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Settings, Status } from 'app/app.util';
import { AppSettingsService } from 'app/shared/api.services/app-settings.service';

@Component({
    selector: 'app-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['./settings-dialog.component.scss'],
    standalone: false
})
export class SettingsDialogComponent implements OnInit {
  isLoading = true;
  isDataChanging = false;
  readonly app_emailer = Settings.AppEmailer;
  readonly notif_emailer = Settings.NotificationEmailer;
  readonly server_down = Settings.ServerDown;
  server_down_range: string = '';
  server_down_from;
  server_down_to;
  notificationEmailProvider;
  appEmailProvider;
  serverStatusDown: boolean;
  serverForm: UntypedFormGroup;

  constructor(private appSettingServices: AppSettingsService,
    public snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadAllSettings();
    });
  }

  loadAllSettings() {
    forkJoin([this.appSettingServices.getEmailerName(this.app_emailer).pipe(
      catchError(error => of(error))
    ), this.appSettingServices.getEmailerName(this.notif_emailer).pipe(
      catchError(error => of(error))
    ), this.appSettingServices.getServerStatus(this.server_down).pipe(
      catchError(error => of(error))
    )
    ]).pipe(
      map(([app_email_provider, notif_email_provider, server_status]) => {
        return { app_email_provider, notif_email_provider, server_status };
      })
    ).subscribe((res: any) => {
      if (this.isLoading) { this.isLoading = false; }
      this.appEmailProvider = res.app_email_provider;
      this.notificationEmailProvider = res.notif_email_provider;
      this.serverStatusDown = res.server_status.status === Status.ACTIVE ? true : false;
      if (this.serverStatusDown) { // show existing dates only if alert is enabled
        const serverDownRange: any = JSON.parse(res.server_status.value);
        this.server_down_from = serverDownRange.from;
        this.server_down_to = serverDownRange.to;
      }

      this.initServerDownForm();
    }, err => {
      this.snackBar.open('Something went wrong while fetching all settings, please try again later.', null, {
        duration: 3000,
      });
      return throwError(err);
    })
  }

  private initServerDownForm() {
    this.serverForm = this.formBuilder.group({
      from: [this.server_down_from || null, [Validators.required]],
      to: [this.server_down_to || null, [Validators.required]],
    });
  }

  onAppEmailerSelection(event: MatSelectChange) {
    this.isDataChanging = true;
    const setting = { name: this.app_emailer, value: event.value };
    this.appSettingServices.updateAppSetting(setting).subscribe(res => {
      this.snackBar.open('App Email provider updated successfully.', null, {
        duration: 2000,
      });
      this.isDataChanging = false;
    }, err => {
      this.snackBar.open('Something went wrong, please try again later.', null, {
        duration: 2000,
      });
      return throwError(err);
    });
  }

  onNotificationEmailerSelection(event: MatSelectChange) {
    this.isDataChanging = true;
    const setting = { name: this.notif_emailer, value: event.value };
    this.appSettingServices.updateAppSetting(setting).subscribe(res => {
      this.snackBar.open('Notification email provider updated successfully.', null, {
        duration: 2000,
      });
      this.isDataChanging = false;
    }, err => {
      this.snackBar.open('Something went wrong, please try again later.', null, {
        duration: 2000,
      });
      return throwError(err);
    });
  }

  onToggleChange(event: MatSlideToggleChange) {
    this.isDataChanging = true;
    const status = event.checked ? Status.ACTIVE : Status.INACTIVE;
    this.server_down_range = JSON.stringify(this.serverForm.getRawValue());
    const setting = { name: this.server_down, value: this.server_down_range, status: status };
    this.appSettingServices.updateAppSetting(setting).subscribe(res => {
      this.snackBar.open('Server down alert updated successfully.', null, {
        duration: 2000,
      });
      this.isDataChanging = false;
    }, err => {
      this.snackBar.open('Something went wrong, please try again later.', null, {
        duration: 2000,
      });
      return throwError(err);
    });
  }

}
