import { environment } from './../../environments/environment.prd';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { HttpService } from './http.service';
import { AgencyService } from './api.services/agency.service';
import { UserService } from './api.services/user.service';
import { GroupService } from './api.services/group.service';
import { LicenseService } from './api.services/license.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CustomHttpInterceptor } from './http.interceptor';
import { ActivityService } from './api.services/activity.service';
import { ActivityNamePipe } from './pipes/activity-name.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ActivityDetailsPipe } from './pipes/activity-details.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgencyCreateComponent } from 'app/agencies/create/agency-create.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UserCreateComponent } from 'app/users/create/user-create.component';
import { LicenseCreateComponent } from 'app/licenses/create/license-create.component';
import { SettingsDialogComponent } from './components/settings-dialog/settings-dialog.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PartnerCollaborationComponent } from './components/partner-collaboration/partner-collaboration.component';
import { LicenseProductNamePipe } from './pipes/license-product-name.pipe';

@NgModule({ declarations: [
        ConfirmDialogComponent,
        CapitalizePipe,
        ActivityNamePipe,
        KeysPipe,
        ActivityDetailsPipe,
        AgencyCreateComponent,
        UserCreateComponent,
        LicenseCreateComponent,
        SettingsDialogComponent,
        CountdownTimerComponent,
        PartnerCollaborationComponent,
        LicenseProductNamePipe,
    ],
    exports: [
        CapitalizePipe,
        ActivityNamePipe,
        KeysPipe,
        ActivityDetailsPipe,
        AgencyCreateComponent,
        PartnerCollaborationComponent,
        LicenseProductNamePipe
    ], imports: [
        // AgmCoreModule.forRoot({
        //     apiKey: environment.google_api_key,
        //     libraries: ['places']
        // }),
        ScrollingModule,
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatRadioModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableModule,
        MatTabsModule,
        MatListModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule], providers: [
        HttpService,
        AgencyService,
        UserService,
        GroupService,
        ActivityService,
        LicenseService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SharedModule { }
