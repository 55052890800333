import { map } from 'rxjs/operators';
import { APIService } from './../../app.constants';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private baseUrl = APIService.AppSetting;

  constructor(private httpService: HttpService) { }

  getEmailerName(setting_name) {
    return this.httpService.get(`${this.baseUrl}/?name=${setting_name}`).pipe(
      map((res: any) => res.data.value)
    );
  }

  getServerStatus(setting_name) {
    return this.httpService.get(`${this.baseUrl}/?name=${setting_name}`).pipe(
      map((res: any) => res.data)
    );
  }

  updateAppSetting(settingObj) {
    return this.httpService.post(`${this.baseUrl}`, settingObj);
  }
}
