import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange, MatListOption } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, debounceTime, filter, flatMap, map, startWith } from 'rxjs/operators';
import { throwError, Observable, forkJoin, of } from 'rxjs';
import { each, findIndex, uniqBy } from 'lodash';
import { Agency } from '../../models/agency';
import { AgencyService } from '../../shared/api.services/agency.service';
// import { MapsAPILoader } from '@agm/core';
import { AgencyTypes, SupportAudioFormats } from 'app/app.constants';
import { SearchService } from 'app/shared/api.services/search.service';
import { SearchSuggestion } from 'app/models/search_suggestion';
import { SessionStorage } from 'ngx-webstorage';
import { PremiumService } from 'app/shared/api.services/premium.service';
import { environment } from 'environments/environment';

declare var google: any;

@Component({
    selector: 'app-agency-create',
    templateUrl: './agency-create.component.html',
    styleUrls: ['./agency-create.component.scss'],
    standalone: false
})
export class AgencyCreateComponent implements OnInit {
  readonly ORIFormat = /([A-Z]{2}[\d]{7})|([A-Z]{5}[\d]{4})|([A-Z]{2}[\d]{6}[A-Z]{1})/g;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly agencyTypes = AgencyTypes
  readonly supportedAudioFormats = SupportAudioFormats;
  @SessionStorage('isLoading', false) loading;
  agencyForm: UntypedFormGroup;
  agency: Agency;
  isEditAction = false;
  mapsAPILoaderPromise: Promise<void>;
  agenciesForCollaboration: Observable<SearchSuggestion[]>;
  collaborators: any[] = [];
  shouldReload: boolean = false;
  loadAgencyObs$: Observable<any>;
  premiumFeatures: any[] = [];
  selectedPremiumFeatures: any[] = [];
  selectedFeatureList = [];
  addressElementRef: ElementRef;
  @ViewChild('agencyInput', { static: false }) agencyInput: ElementRef<HTMLInputElement>;
  @ViewChild('address_autocomplete', { static: false }) set content(content: ElementRef) {
    this.addressElementRef = content;
    if (this.addressElementRef) {
      this.initAddressAutoComplete();
    }
  }
  agencies: any[] = [];
  filteredAgencies: Observable<any[]>;
  selectedAgencies: any[] = [];

  // Add error message property
  authValidationError: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AgencyCreateComponent>,
    private router: Router,
    private agencyService: AgencyService,
    private searchService: SearchService,
    private premiumService: PremiumService,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private ngZone: NgZone,) {
    // this.mapsAPILoaderPromise = this.mapsAPILoader.load();
    this.mapsAPILoaderPromise = this.loadGoogleMapsAPI();
  }

  private loadGoogleMapsAPI(): Promise<void> {
    return new Promise((resolve) => {
      if (window.google && window.google.maps) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google_api_key}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        document.body.appendChild(script);
      }
    });
  }

  ngOnInit() {
    if (this.data && this.data.id) { // edit action
      this.isEditAction = true;
    } else { // create action
      this.isEditAction = false;
      this.initAgencyForm();
    }
    this.initializeAgencyData();
    setTimeout(() => {
      this.loadData();
    });

  }

  private _filter(value: string): any[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.agencies.filter(agency => agency.name.toLowerCase().includes(filterValue));
  }

  addCollaboratingAgency(event: MatAutocompleteSelectedEvent): void {
    const selectedAgency = event.option.value;
    if (findIndex(this.selectedAgencies, (c) => c.id === selectedAgency.id) === -1)
      this.selectedAgencies.push(event.option.value);

    this.collaboratorSearchInput.setValue(null);
    this.agencyInput.nativeElement.value = '';
  }

  private initializeAgencyData() {
    this.loadAgencyObs$ = this.isEditAction ? forkJoin([this.agencyService.load(this.data.id).pipe(catchError(err => of(err))),
    this.premiumService.getAll().pipe(catchError(err => of(err)))]).pipe(map(([agency, prem_feat]) => {
      return { agency, prem_feat };
    })) : this.premiumService.getAll();
  }

  private loadData() {
    this.loadAgencyObs$.subscribe((res: any) => {
      if (res.agency) {
        this.agency = res.agency;
        this.selectedAgencies = res.agency.collaborating_agencies;
        this.initAgencyForm();
      }
      this.premiumFeatures = res.prem_feat ? res.prem_feat : res;

      if (this.isEditAction) {
        if (this.agency.premium_features && this.agency.premium_features.length) {
          this.selectedPremiumFeatures = [...this.agency.premium_features];
          const tempFeats = this.selectedPremiumFeatures.map(f => f.id);
          this.premiumFeatures = this.premiumFeatures.filter(f => tempFeats.indexOf(f.id) === -1);
        }
      }
    }, err => {
      this.snackBar.open('Something Went Wrong While Getting The Agency Data', null, {
        duration: 4000,
      });
      return throwError(err);
    });
  }

  private initAddressAutoComplete() {
    this.mapsAPILoaderPromise.then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement, {
        types: ['geocode'],
        componentRestrictions: { country: 'us' },
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.agencyForm.get('address_line1').setValue(place.name);
          each(place.address_components, (c) => {
            switch (c.types[0]) {
              case 'locality':
                this.agencyForm.get('city').setValue(c.long_name);
                break;
              case 'sublocality_level_1':
                this.agencyForm.get('address_line2').setValue(c.long_name);
                break;
              case 'administrative_area_level_1':
                this.agencyForm.get('state').setValue(c.long_name);
                break;
              case 'postal_code':
                this.agencyForm.get('zipcode').setValue(c.long_name);
                break;
            }
          })
        });
      });
    });
  }

  private loadAllAgencies() {
    this.agencyService.loadAllTypeAgencies().subscribe(agencyRes => {
      agencyRes.forEach(agency => {
        this.agencies.push({ id: agency.id, ori_number: agency.ori_number, name: agency.name });
      });
      this.agencies = uniqBy(this.agencies, (agency: any) => agency.id); // remove duplicates
    }, err => {
      this.snackBar.open('Something Went Wrong While Loading Agencies', null, {
        duration: 4000,
      });
      return throwError(err);
    });
  }

  private initAgencyForm() {
    setTimeout(() => this.loadAllAgencies());
    const isEmailAuth = this.agency?.preferences?.is_email_auth;
    this.agencyForm = this.formBuilder.group({
      name: [(this.agency && this.agency.name) || '', [Validators.required]],
      ori_number: [(this.agency && this.agency.ori_number) || '', [
        Validators.maxLength(9),
        Validators.pattern(this.ORIFormat),
      ]],
      address_line1: [(this.agency && this.agency.address_line1) || '', [Validators.required]],
      address_line2: [(this.agency && this.agency.address_line2) || '', null],
      city: [(this.agency && this.agency.city) || '', [Validators.required]],
      state: [(this.agency && this.agency.state) || '', [Validators.required]],
      zipcode: [(this.agency && this.agency.zipcode) || '', [Validators.required]],
      email_domains: [(this.agency && this.agency.email_domains) || '', null],
      type: [(this.agency && this.agency.type) || '', null],
      preferences: this.formBuilder.group({
        default_audio_format: [(this.agency && this.agency.preferences && this.agency.preferences.default_audio_format) || 'original']
      }),
      is_email_auth: [isEmailAuth !== false || isEmailAuth === undefined],
      is_ad_auth: [(this.agency && this.agency.preferences && this.agency.preferences.is_ad_auth) || false],
      collaborator_search: [null],
      premium_features: [(this.agency && this.agency.premium_features) || [], null]
    });

    // Add value change subscriptions for authentication toggles
    this.agencyForm.get('is_email_auth').valueChanges.subscribe(emailAuth => {
      if (!emailAuth && !this.agencyForm.get('is_ad_auth').value) {
        this.authValidationError = 'At least one authentication method must be enabled';
        this.agencyForm.get('is_email_auth').setValue(true, { emitEvent: false });
      } else {
        this.authValidationError = '';
      }
    });

    this.agencyForm.get('is_ad_auth').valueChanges.subscribe(adAuth => {
      if (!adAuth && !this.agencyForm.get('is_email_auth').value) {
        this.authValidationError = 'At least one authentication method must be enabled';
        this.agencyForm.get('is_ad_auth').setValue(true, { emitEvent: false });
      } else {
        this.authValidationError = '';
      }
    });

    this.filteredAgencies = this.collaboratorSearchInput.valueChanges.pipe(
      startWith(''),
      map((value: string | any) => value && typeof (value) === 'string' ? this._filter(value as string) : this.agencies.slice())
    );
  }

  get collaboratorSearchInput() {
    return this.agencyForm.get('collaborator_search');
  }

  onPremiumFeatSelection(event: MatSelectionListChange, selected: MatListOption[]) {
    const option = event.options[0];
    if (option.selected) {
      this.selectedPremiumFeatures.push(option.value);
      this.selectedFeatureList = [...selected];
      this.agencyForm.get('premium_features').setValue([...this.selectedPremiumFeatures.map(val => val.id)]);
    } else {
      this.remove(option.value);
    }
  }

  remove(premium_feat) {
    const index = this.selectedPremiumFeatures.indexOf(premium_feat);
    if (index >= 0) {
      this.selectedPremiumFeatures.splice(index, 1);
    }
    if (this.premiumFeatures.indexOf(premium_feat) === -1) {
      this.premiumFeatures.push(premium_feat);
    }

    this.agencyForm.get('premium_features').setValue([...this.selectedPremiumFeatures.map(val => val.id)]);

    this.selectedFeatureList.forEach((selectedVal: MatListOption) => {
      if (selectedVal.value.id === premium_feat.id) {
        selectedVal.selected = false;
      }
    });
  }

  onSubmit() {
    if (this.authValidationError) {
      this.snackBar.open(this.authValidationError, null, {
        duration: 3000,
      });
      return;
    }

    const formData: any = this.agencyForm.getRawValue();

    formData.preferences = {
      ...formData.preferences,
      is_email_auth: formData.is_email_auth,
      is_ad_auth: formData.is_ad_auth
    };

    formData.collaborating_agencies = this.selectedAgencies.map(c => c.id);
    formData.premium_features = formData.premium_features.map(pf => pf.id ? pf.id : pf);

    if (this.isEditAction) {
      formData.id = this.agency.id;
    }
    this.agencyService[this.isEditAction ? 'update' : 'create'](formData).pipe(catchError((error: Response) => {
      this.loading = false;
      this.snackBar.open('Agency creation failed!', null, {
        duration: 3000,
      });
      return throwError(error);
    })).subscribe((response: any) => {
      this.shouldReload = true;
      this.dialogRef.close(this.shouldReload);
      if (this.isEditAction === true) {
        this.snackBar.open('Agency details updated!', null, {
          duration: 3000,
        })
      } else {
        this.snackBar.open('New agency created!', null, {
          duration: 3000,
        });
      }
    });
  }
}

