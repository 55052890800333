<mat-card>
    <mat-card-title>
        Pending Collaboration Requests
    </mat-card-title>

    <div>
        <mat-card-content>
            <mat-progress-bar mode="indeterminate" *ngIf="isActionLoading"></mat-progress-bar>
            <div align="center" style="margin: 20px;" *ngIf="isLoading">
                <mat-spinner color="primary" diameter="60"></mat-spinner>
            </div>

            <ng-container *ngIf="!isLoading">

                <div class="no-data-message" *ngIf="_dataSource?.length === 0">
                    <mat-icon>error</mat-icon>
                    No data available!
                </div>

                <table style="width: 100%;" mat-table [dataSource]="_dataSource" class="mat-elevation-z8"
                    *ngIf="_dataSource?.length > 0">

                    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                    <!-- Position Column -->
                    <ng-container matColumnDef="srno">
                        <th mat-header-cell *matHeaderCellDef> Collab Ref</th>
                        <td mat-cell *matCellDef="let element"> {{element.collaboration_ref_id}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="data">
                        <th mat-header-cell *matHeaderCellDef> Details </th>
                        <td mat-cell *matCellDef="let element">
                            Karpel entity with ORI <b>{{element.partner_ori_number}}</b> wants to connect with iCF
                            Agency <b>{{element.ori_number}}</b>
                            <br>
                            Requestor Name : <b>{{element.requestor_name}}</b>
                            <br>
                            Requestor Email : <b>{{element.requestor_email}}</b>
                            <br>
                            Requestor Phone : <b>{{element.requestor_phone}}</b>
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element">
                            <!--
                                Accept and reject mat-button with icons and click events
                             -->
                            <button mat-raised-button color="primary" (click)="onAcceptRequest(element.id)">
                                <mat-icon>check</mat-icon>
                                Accept
                            </button>
                            <button mat-raised-button color="warn" (click)="onRejectRequest(element.id)">
                                <mat-icon>clear</mat-icon>
                                Reject
                            </button>

                        </td>

                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: _displayedColumns;"></tr>
                </table>

            </ng-container>

        </mat-card-content>
    </div>
</mat-card>