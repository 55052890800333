import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { defaultsDeep } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerApiService {
  private baseUrl = environment.partner_api_url;

  constructor(protected http: HttpClient) { }

  private reqInterceptor(reqOptions: any) {
    return defaultsDeep(reqOptions, {
      params: {
        // add any global params here, which can be overwritten in the call
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    });
  }

  get(url: string, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this.reqInterceptor(options);
    return this.http.get(url, options);
  }

  post(url: string, body?: any, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this.reqInterceptor(options);
    return this.http.post(url, body, options);
  }

  patch(url: string, body?: any, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this.reqInterceptor(options);
    return this.http.patch(url, body, options);
  }

  put(url: string, options?: any) {
    url = this.baseUrl + url;
    options = this.reqInterceptor(options);
    return this.http.put(url, options);
  }

  delete(url: string, options?: any): Observable<ArrayBuffer> {
    url = this.baseUrl + url;
    options = this.reqInterceptor(options);
    return this.http.delete(url, options);
  }
}
