import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/auth/auth.service';

@Component({
    selector: 'app-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls: ['./countdown-timer.component.scss'],
    standalone: false
})
export class CountdownTimerComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const fiveMinutes = 60 * 5;
    setTimeout(() => {
      const display = document.querySelector('#time');
      this._startTimer(fiveMinutes, display);
    });
  }

  private _startTimer(duration, display) {
    let timer = duration, minutes, seconds;
    setInterval(() => {
      // @ts-ignore
      minutes = parseInt((timer / 60), 10);
      // @ts-ignore
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  onRefresh() {
    this.snackBar.dismiss();
    this.authService.updateAuthUser();
  }

}
