import { Injectable } from '@angular/core';
import { PartnerApiService } from '../partner-api.service';
import { APIService } from 'app/app.constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerCollaborationService {
  private baseUrl = APIService.OnBoadring;
  constructor(private partnerAPIService: PartnerApiService) { }

  getCollaborationRequests() {
    return this.partnerAPIService.get(`${this.baseUrl}/collaboration`).pipe(
      map((res: any) => res.data)
    );
  }

  submitCollaborationRequest(partner_collaboration_request_id: number, action: string,reason?:string) {
    const params: any = { partner_collaboration_request_id, action,reason };
    return this.partnerAPIService.patch(`${this.baseUrl}/collaboration`, params);
  }
}
