import { ApiError, Status } from './../../app.util';
import { includes } from 'lodash';
import { CasePermissions, PermissionInfoTexts } from './../../models/permissions';
import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange, MatListOption } from '@angular/material/list';
import { MatRadioChange, MatRadioButton } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../models/user';
import { UserService } from '../../shared/api.services/user.service';
import { SessionStorage } from 'ngx-webstorage';
import { catchError, filter, startWith } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { PermissionsService } from 'app/shared/api.services/permissions.service';
import { Permissions } from 'app/models/permissions';

@Component({
    selector: 'app-agency-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
    standalone: false
})
export class UserCreateComponent implements OnInit {
  @SessionStorage('isLoading', false) loading;
  userRoles = {
    'Supervisor': 7,
    'Officer': 6,
    'Support': 8,
    'Records': 10
  };
  createPermissionId = 31;
  userForm: UntypedFormGroup;
  user: User;
  isEditAction = false;
  allPermissions = [];
  permissions = [];
  allAvailablePermissions = [];
  userPermissions = [];
  permissionChange = false;
  isModified = false;
  emailNotificationData;
  selectedCasePermissions;
  selectedSharingPermissions;
  selectedAdminpermissons;
  selectedLoginPermissions;
  private case_Permissions;
  private sharing_Permissions;
  private admin_Permissions;
  private login_permissions;
  readonly CasePermissions = CasePermissions;
  readonly PermissionInfoTexts = PermissionInfoTexts;
  readonly Status = Status;
  existingEmail: string = '';
  readonly confirmationPhrase: string = 'CONFIRM';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UserCreateComponent>,
    private router: Router,
    private userService: UserService,
    private permissionsService: PermissionsService,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    if (this.data && this.data.id) { // edit action
      this.isEditAction = true;
      this.userService.get(this.data.id).subscribe(u => {
        if (!u) {
          return this.router.navigateByUrl(`app/agencies/users/${this.data.agencyId}`);
        }
        this.user = u;
        this.isModified = this.user.role['id'] === 9;
        this.initUserForm();
        this.existingEmail = this.userForm.get('email_address').value;
      });
    } else { // create action
      this.isEditAction = false;
      this.initUserForm();
    }
    if (this.isEditAction) { // fetching existing user permissions in user edit form
      setTimeout(() => { // using settimeout to resolve 'expression has checked' angular error
        this.userService.getPermissions(this.data.id, this.data.agencyId).subscribe(res => {
          if (!res) {
            return this.router.navigateByUrl(`app/agencies/users/${this.data.agencyId}`);
          }
          this.permissions = res['data']['permissions']
            .filter((permission: Permissions) => permission.status === Status.ACTIVE && permission.name !== CasePermissions.CASE_CREATE); // hiding create permission getting active permissions
          this.userPermissions = [...this.permissions];
          this.getAllPermissions();
        }, (err) => {
          this.snackBar.open('Something went wrong while getting the user permissions', null, {
            duration: 2000,
          });
          console.log(err);
        });
      });
    } else {
      this.getAllPermissions();
    }
    this.userForm.get('confirmation').disable();
    this.userFormChecker();
  }

  private userFormChecker(): void {
    /* enable confirmation form control in case the email_address field is updated */
    if (this.isEditAction) {
      this.userForm.get('email_address').valueChanges.subscribe(updatedEmail => {
        if (this.existingEmail !== updatedEmail) {
          this.userForm.get('confirmation').enable();
        } else this.userForm.get('confirmation').disable();
      });
    }
  }

  get confirmation(): AbstractControl {
    return this.userForm.get('confirmation');
  }

  private getAllPermissions() {
    setTimeout(() => { // using settimeout to resolve 'expression has checked' angular error
      this.permissionsService.getAllPermissions(this.data.agencyId).subscribe(res => {
        this.allPermissions = res['data']
          .filter((permission: Permissions) => permission.name !== CasePermissions.CASE_CREATE); // hiding create permission

        this.allAvailablePermissions = [...this.allPermissions];

        if (this.isEditAction || this.permissions.length > 0) {
          const tempPermissions = this.permissions.map(p => p.name);
          this.allPermissions = this.allPermissions.filter(p => tempPermissions.indexOf(p.name) === -1);
        }
        this.filterPermissionByType(this.allPermissions);
      }, (err) => {
        this.snackBar.open('Something went wrong while getting the permissions', null, {
          duration: 2000,
        });
        console.log(err);
      });
    });
  }

  private filterPermissionByType(_permissions) {
    this.case_Permissions = _permissions.filter(p => p.name === CasePermissions.CASE_EDIT
      || p.name === CasePermissions.CASE_MERGE || p.name === CasePermissions.CASE_PRINT
      || p.name === CasePermissions.DELETE_RESTORE
      || p.name === CasePermissions.ARCHIVE_UNARCHIVE
      || p.name === CasePermissions.CASE_UPLOAD
      || p.name === CasePermissions.RESTRICT_CASE
      || p.name === CasePermissions.CASE_DOWNLOAD
      || p.name === CasePermissions.EVIDENCE_ANYWHERE);

    this.admin_Permissions = _permissions.filter(p => p.name === CasePermissions.AGENCY_UPDATE
      || p.name === CasePermissions.USER_MANAGEMENT || p.name === CasePermissions.SUPER_ADMIN || p.name === CasePermissions.MANAGE_API_CLIENTS);

    this.sharing_Permissions = _permissions.filter(p => p.name === CasePermissions.VIEW_SHARED_CASE
      || p.name === CasePermissions.CASE_SHARE_AGENCY || p.name === CasePermissions.CASE_SHARE_EMAIL);

    this.login_permissions = _permissions.filter(p => p.name === CasePermissions.MOBILE_LOGIN
      || p.name === CasePermissions.CLOUD_LOGIN || p.name === CasePermissions.DESKTOP_LOGIN);
  }

  private initUserForm() {
    this.userForm = this.formBuilder.group({
      first_name: [(this.user && this.user.first_name) || null, [Validators.required]],
      last_name: [(this.user && this.user.last_name) || null, [Validators.required]],
      email_address: [(this.user && this.user.email_address) || null, [Validators.required, Validators.email]],
      mobile_number: [(this.user && this.user.mobile_number) || null],
      alt_contact: [(this.user && this.user.alt_contact) || null],
      role: [(this.user && (this.user['role']['role_id'] || this.user['role']['id'])) || null, [Validators.required]],
      agency_id: [(this.user && this.user.agency_id) || (this.data && this.data.agencyId), [Validators.required]],
      confirmation: ['', [Validators.required, this.confirmationValidator()]]
    });

    this.userForm.setValidators([(g: UntypedFormGroup) => {
      const mobile = g.get('mobile_number')?.value;
      const alternateMobile = g.get('alt_contact')?.value;
      const _getValidatation = (contactNumber, alternateContact) => {
        if (!contactNumber && !alternateContact) return null;
        const MOBILE_REGEXP = /^\d{10}$/;
        if (MOBILE_REGEXP.test(contactNumber) && MOBILE_REGEXP.test(alternateContact)) return null;
        const errors: any = {};
        if (contactNumber && !MOBILE_REGEXP.test(contactNumber)) errors.invalidMobileNumber = true;
        if (alternateContact && !MOBILE_REGEXP.test(alternateContact)) errors.invalidAltNumber = true;
        return errors;
      };

      return _getValidatation(mobile, alternateMobile);
    }]);
  }

  private confirmationValidator() {
    return (control: UntypedFormControl) => {
      if (control.value.trim().toUpperCase() === this.confirmationPhrase) {
        return null;
      } else {
        return { 'confirmation_error': true }
      }
    };
  }

  remove(permission): void {
    const index = this.permissions.indexOf(permission);
    if (index >= 0) {
      this.permissions.splice(index, 1);
    }
    this.updatePermissionSelecton(permission);

    /*
      if "browser access" permission is removed, then
      remove all permissions that are associated with cloud access
    */
    if (permission.name === CasePermissions.CLOUD_LOGIN) {
      const other_perms = this.permissions
        .filter(p => p.name !== CasePermissions.MOBILE_LOGIN && p.name !== CasePermissions.DESKTOP_LOGIN);
      other_perms.forEach(perm => {
        this.updatePermissionSelecton(perm);
      });
      this.permissions = this.permissions
        .filter(p => p.name === CasePermissions.MOBILE_LOGIN || p.name === CasePermissions.DESKTOP_LOGIN);
    }
  }

  private updatePermissionSelecton(permission) {
    if (this.allPermissions.indexOf(permission) === -1) {
      this.allPermissions.push(permission);
      this.filterPermissionByType(this.allPermissions);
    }

    // checking the removed user permission's type...
    if (this.selectedCasePermissions && this.selectedCasePermissions.some((val: MatListOption) => val.value.id === permission.id)) {
      // if the removed user permission is same as available permission then deselect the selected checkbox
      this.selectedCasePermissions.forEach((selectedVal: MatListOption) => {
        if (selectedVal.value.id === permission.id) {
          selectedVal.selected = false;
        }
      });
    } else if (this.selectedSharingPermissions
      && this.selectedSharingPermissions.some((val: MatListOption) => val.value.id === permission.id)) {
      // if the removed user permission is same as available permission then deselect the selected checkbox
      this.selectedSharingPermissions.forEach((selectedVal: MatListOption) => {
        if (selectedVal.value.id === permission.id) {
          selectedVal.selected = false;
        }
      });
    } else if (this.selectedLoginPermissions
      && this.selectedLoginPermissions.some((val: MatListOption) => val.value.id === permission.id)) {
      // if the removed user permission is same as available permission then deselect the selected checkbox
      this.selectedLoginPermissions.forEach((selectedVal: MatListOption) => {
        if (selectedVal.value.id === permission.id) {
          selectedVal.selected = false;
        }
      });
    } else {
      // if the removed user permission is same as available permission then deselect the selected checkbox
      if (this.selectedAdminpermissons) {
        this.selectedAdminpermissons.forEach((selectedVal: MatListOption) => {
          if (selectedVal.value.id === permission.id) {
            selectedVal.selected = false;
          }
        });
      }
    }
    this.permissionChange = true;
    this.isModified = true;
  }

  onPermissionSelection(event: MatSelectionListChange, selected: MatListOption[], permissionType: string) {
    const option = event.options[0];
    if (option.selected) {
      this.permissions.push(option.value);
      this.permissionChange = true;
      this.isModified = true;
      switch (permissionType) { // categorizing the permission based on type
        case 'case':
          this.selectedCasePermissions = [...selected];
          break;
        case 'share':
          this.selectedSharingPermissions = [...selected];
          break;
        case 'admin':
          this.selectedAdminpermissons = [...selected];
          break;
        case 'login':
          this.selectedLoginPermissions = [...selected];
      }
    } else {
      this.remove(option.value);
    }
  }

  onRoleSelection(event: MatRadioChange) {
    if (event.value === 6) { // officer
      this.allPermissions = [...this.allAvailablePermissions];
      this.permissions = this.allPermissions.filter(p => p.name === CasePermissions.CASE_EDIT
        || p.name === CasePermissions.CASE_UPLOAD
        || p.name === CasePermissions.CASE_DOWNLOAD || p.name === CasePermissions.CASE_PRINT
        || p.name === CasePermissions.MOBILE_LOGIN
        || p.name === CasePermissions.CLOUD_LOGIN);
    } else if (event.value === 7) { // supervisor
      this.allPermissions = [...this.allAvailablePermissions];
      // excluding super admin permission from supervisor role
      // this.permissions = [...this.allAvailablePermissions];
      this.permissions = this.allPermissions.filter(p => p.name !== CasePermissions.SUPER_ADMIN && p.name !== CasePermissions.DESKTOP_LOGIN);
    } else if (event.value === 8) { // support
      this.allPermissions = [...this.allAvailablePermissions];
      this.permissions = this.allPermissions.filter(p => p.name === CasePermissions.CASE_EDIT
        || p.name === CasePermissions.CASE_DOWNLOAD
        || p.name === CasePermissions.CASE_PRINT || p.name === CasePermissions.CASE_UPLOAD
        || p.name === CasePermissions.CASE_MERGE || p.name === CasePermissions.DELETE_RESTORE
        || p.name === CasePermissions.ARCHIVE_UNARCHIVE
        || p.name === CasePermissions.CLOUD_LOGIN);
    } else if (event.value === 10) { // Records
      this.allPermissions = [...this.allAvailablePermissions];
      this.permissions = this.allPermissions.filter(p => p.name === CasePermissions.CASE_EDIT
        || p.name === CasePermissions.CASE_DOWNLOAD
        || p.name === CasePermissions.CASE_PRINT || p.name === CasePermissions.CASE_UPLOAD
        || p.name === CasePermissions.CASE_MERGE || p.name === CasePermissions.DELETE_RESTORE
        || p.name === CasePermissions.ARCHIVE_UNARCHIVE
        || p.name === CasePermissions.CLOUD_LOGIN
        || p.name === CasePermissions.VIEW_SHARED_CASE
        || p.name === CasePermissions.CASE_SHARE_EMAIL
        || p.name === CasePermissions.CASE_SHARE_AGENCY);
    }
    this.permissions = this.permissions.filter(p => p.status === Status.ACTIVE); // display only the active permissions
    const tempPermissions = this.permissions.map(p => p.id);
    this.allPermissions = this.allPermissions.filter(p => tempPermissions.indexOf(p.id) === -1);
    this.filterPermissionByType(this.allPermissions);
    this.permissionChange = true;
    this.isModified = false;
  };

  hasPermission(searchParam) {
    return includes(this.permissions.map(p => p.name), searchParam);
  }

  // onNotificationToggleChange(event: MatSlideToggleChange) {
  //   this.emailNotificationData = event;
  // }

  private updateOrCreateUser(data) {
    this.userService[this.isEditAction ? 'update' : 'create'](data).subscribe({
      next: (res: any) => {
        if (this.permissionChange && this.isEditAction) { // update user permissions in edit user form
          this.updateUserPermissions(data.role);
        }

        this.dialogRef.close({ userId: this.isEditAction ? res.id : res.data.id });
        this.snackBar.open(this.isEditAction ? 'User details updated!' : 'New user added!', null, {
          duration: 3000,
        });
      },
      error: (err) => _errorHandler(err)
    });

    const _errorHandler = (error) => {
      let message;
      if (this.isEditAction) message = 'User Details Update Failed!';
      else {
        const errorMessages = {
          [ApiError.AccountLimitReached]: 'Account limit reached, unable to create user!',
          [ApiError.UserAlreadyExists]: error?.error?.error?.icf_data?.custom_message
        };
        message = errorMessages[error?.error?.error?.name] || 'Unable to create user!'
      }
      this.snackBar.open(`${message}`, null, { duration: 3000 })
      return throwError(() => error);
    }
  }

  private updateUserPermissions(roleId) {
    this.userForm.get('confirmation').disable();
    const updatedPermissions = this.permissions.map(p => p.id);
    updatedPermissions.push(this.createPermissionId); // adding create permission as default
    const data = {
      role_id: roleId,
      permissions: updatedPermissions
    };
    this.userService.updatePermissions(this.user.id, data).subscribe(res => {
      if (res['status']) {
        this.dialogRef.close({ userId: this.user.id });
        this.snackBar.open('User permissions updated successfully', null, {
          duration: 2000,
        });
      }
    }, (err) => {
      this.snackBar.open('Something went wrong while updating the user permissions', null, {
        duration: 2000,
      });
      console.log(err);
    });
  }

  onSubmit() {
    if (this.permissions.length > 0) {
      const formData = this.userForm.getRawValue();

      if (this.isEditAction) {
        formData.id = this.user.id;
      }

      if (!this.isEditAction) { // create action
        const userFormPermissions = this.permissions.map(p => p.id);
        userFormPermissions.push(this.createPermissionId); // adding create permission as default
        formData.role_id = formData.role;
        formData.permissions = [...userFormPermissions];
      }

      if (this.isModified) {
        formData.role = 9;
        formData.role_id = formData.role;
      }

      // diabling shared case notification setting

      // if (this.emailNotificationData) { // email notification toggle changed
      //   formData.preferences = { email_notifications: this.emailNotificationData.checked };
      // }

      // // removal of 'view shared case' permission will disable the shared case email notification alert
      // if (this.isEditAction && this.permissions.indexOf(CasePermissions.VIEW_SHARED_CASE) === -1) {
      //   if (this.user['preferences'] && this.user['preferences']['email_notifications'] === true) {
      //     formData.preferences = { email_notifications: false };
      //   }
      // }

      this.updateOrCreateUser(formData);
    } else {
      this.snackBar.open('Please add some user permissions', null, {
        duration: 2000,
      });
    }
  }
}
