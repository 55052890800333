import { Events } from "./events";

export interface Activity {
  event_name: string;
  created_at: string;
  created_by: string;
  meta_data: ActivityDetail;
}

export interface ActivityDetail {
  status: string;
  user_name?: string;
  ipaddress: string;
  useragent: {
    family: string;
    major: string;
    minor: string
    patch: string;
    source: string;
  };
  location: {
    city: string;
    country_code: string;
    country_name: string;
    ip: string;
    latitude: string;
    longitude: string;
    metro_code: number;
    region_code: string;
    region_name: string;
    time_zone: string;
    zip_code: null;
  };
  [key: string]: any;
}

export const ActivityType = {
  'agency.account.created': 'Agency creation',
  'agency.account.edited': 'Agency details update',
  [Events.AgencyDeleted]: 'Agency deleted',
  [Events.AgencyRestored]: 'Agency restored',
  'agency.account.license.added': 'License added',
  'agency.account.license.edited': 'License updated',
  'agency.account.group.created': 'Group creation',
  'agency.account.group.users_updated': 'Group user updated',
  'agency.account.group.archived': 'Group archived',
  'agency.account.group.unarchived': 'Group unarchived',
  [Events.AgencyExternalClientCreated]: 'Partner client created',
  [Events.AgencyExternalClientEdited]: 'Partner client edited',
  [Events.AgencyExternalClientDeleted]: 'Partner client Deleted',
  [Events.AgencyTagCreated]: 'Tag Created',
  [Events.AgencyTagUpdated]: 'Tag Updated',

  // 'agency.user.login': 'Login',
  // 'agency.user.logout': 'Logout',
  // 'agency.user.account.recovery': 'Password reset',
  // 'agency.user.account.activation': 'Account activation',
  // 'agency.user.account.action': 'User account activity',
  // 'agency.user.account.created': 'User creation',

  'agency.user.login.success': 'Login success',
  'agency.user.login.failed': 'Login failed',
  'agency.user.logout': 'Logout',
  'agency.user.account.recovery': 'Account recovery',
  'agency.user.account.activation': 'Account activation',
  'agency.user.account.action': 'Account action',
  'agency.user.account.created': 'Account created',

  'agency.case.created': 'Case created',
  'agency.case.viewed': 'Case viewed',
  'agency.case.restricted': 'Case restricted',
  'agency.case.edited': 'Case details updated',
  'agency.case.archived': 'Case archived',
  'agency.case.archive_restored': 'Archived case restored',
  'agency.case.deleted': 'Case deleted',
  'agency.case.deleted_restored': 'Deleted case restored',
  'agency.case.merged': 'Cases merged',
  'agency.case.collaborator.added': 'Collaborator added',
  'agency.case.collaborator.removed': 'Collaborator removed',
  'agency.case.external_user_invited': 'External user invited',
  [Events.CaseEarlyPurgeSchedule]: 'Case early purge schedule',
  [Events.CaseDoNotDeleteEnabled]: 'Case marked as DND',
  [Events.CaseDoNotDeleteDisabled]: 'Case unmarked as DND',
  [Events.CasePurged]: 'Case purged',

  'agency.case.folder.created': 'Folder Created',
  'agency.case.folder.updated': 'Folder Updated',
  'agency.case.folder.deleted': 'Folder Deleted',
  'agency.case.folder.restored': 'Folder Restored',

  'agency.case.file.uploaded': 'File uploaded',
  'agency.case.file.uploaded_external': 'External file uploaded',
  [Events.MaliciousFileFound]: 'Malicious file found',
  [Events.FileScanSkipped]: 'File scan skipped',
  [Events.PUAFileFound]: 'PUA file found',
  [Events.SensitiveFileViewed]: 'Sensitive file viewed',
  'agency.case.file.edited': 'File details updated',
  'agency.case.file.copied': 'File copied',
  'agency.case.file.moved': 'File moved',
  'agency.case.file.archived': 'File archived',
  'agency.case.file.archive_restored': 'Archived file restored',
  'agency.case.file.deleted': 'File deleted',
  'agency.case.file.deleted_restored': 'Deleted file restored',
  'agency.case.file.shared.email': 'Files shared/downloaded via email',
  'agency.case.file.download_requested': 'Files download requested',
  'agency.case.file.downloaded': 'Files downloaded',
  [Events.FilePurged]: 'Files purged',
  [Events.FolderPurged]: 'Folders purged',
  [Events.FileFolderEarlyPurgeSchedule]: 'File/Folder early purge schedule',
  [Events.ApiClientCreated]: 'API client created',
  [Events.ApiClientUpdated]: 'API client updated',
  [Events.ApiClientDeactivated]: 'API client deactivated',
  [Events.ApiClientTokenSuccess]: 'API client token success',
  [Events.ApiClientTokenFailure]: 'API client token failure',
  [Events.ApiClientInvalidCredentials]: 'API client invalid credentials',
  [Events.ApiClientTokenRefreshSuccess]: 'API client token refresh success',
  [Events.ApiClientTokenRefreshFailure]: 'API client token refresh failure',
  [Events.ApiClientAgencyAccessed]: 'API client agency accessed',
  [Events.ApiClientCasesFetched]: 'API client cases fetched',
  [Events.ApiClientCaseEvidenceAccessed]: 'API client case evidence accessed',
  [Events.ApiClientCaseAccessed]: 'API client case accessed',
  [Events.ApiClientFileDownloaded]: 'API client file downloaded',
  [Events.ApiClientFileStreamed]: 'API client file streamed',
  [Events.CaseTagAdded]: 'Case Tag Added',
  [Events.CaseTagDeleted]: 'Case Tag Removed',
  [Events.FileTagAdded]: 'File Tag Added',
  [Events.FileTagRemoved]: 'File Tag Removed'
};
