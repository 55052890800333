import { getTitleCase } from "app/app.util";

export const CasePermissions = {
  CASE_CREATE: 'create',
  CASE_EDIT: 'case_edit',
  CASE_UPLOAD: 'case_upload',
  CASE_DOWNLOAD: 'case_download',
  DELETE_RESTORE: 'case_delete',
  ARCHIVE_UNARCHIVE: 'case_archive',
  CASE_SHARE_AGENCY: 'case_share_agency',
  CASE_SHARE_EMAIL: 'case_share_via_email',
  CASE_PRINT: 'case_print',
  CASE_MERGE: 'case_merge',
  USER_MANAGEMENT: 'user_management',
  AGENCY_UPDATE: 'agency_update',
  RESTRICT_CASE: 'case_restrict',
  VIEW_SHARED_CASE: 'view_shared_cases',
  SUPER_ADMIN: 'super_admin',
  MOBILE_LOGIN: 'mobile_login',
  CLOUD_LOGIN: 'cloud_login',
  DESKTOP_LOGIN: 'desktop_login',
  EVIDENCE_ANYWHERE: 'evidence_anywhere',
  MANAGE_API_CLIENTS: 'manage_api_clients'
};

export interface Permissions {
  id: number,
  name: string,
  desc: string,
  status?: number
};

export const PermissionInfoTexts = {
  case_merge: `${getTitleCase('ability to merge cases. needs browser access permission.')}`,
  case_print: `${getTitleCase('ability to print a case. needs browser access permission.')}`,
  case_edit: `Ability To Edit Case Details. Needs Browser Access Permission.`,
  case_download: `${getTitleCase('ability to download case files. needs browser access permission.')}`,
  case_upload: `${getTitleCase('Ability to upload from browser. Needs Browser access permission.')}`,
  case_delete: `${getTitleCase('Ability to delete or restore cases and files. Needs Browser access permission.')}`,
  case_archive: `${getTitleCase('Ability to archive or unarchive cases and files. Needs Browser access permission.')}`,
  case_share_agency: `${getTitleCase('Ability to share cases with collaborators like other agencies, District Attorneys etc. Needs Browser access permission.')}`,
  case_share_via_email: `${getTitleCase('Ability to share case files by email. Needs Browser access permission.')}`,
  user_management: `${getTitleCase('Ability to manage users in the agency and to view user activity history. Needs Browser access permission.')}`,
  agency_update: `${getTitleCase('Ability to update agency information in iCF. Needs Browser access permission.')}`,
  case_restrict: `${getTitleCase('Ability to restrict cases to some users in the agency. Needs Browser access permission.')}`,
  view_shared_cases: `${getTitleCase('Ability to view cases shared by collaborators. Needs Browser access permission.')}`,
  super_admin: `${getTitleCase('Ability to view all cases even when they are restricted. Needs Browser access permission.')}`,
  mobile_login: `${getTitleCase('Ability to create and upload cases from iOS and Android mobile iCF app.')}`,
  cloud_login: `${getTitleCase('Ability to login to iCF using one of the browsers either on the desktop or mobile.')}`,
  desktop_login: `${getTitleCase('Ability to use the iCF desktop sync app.')}`,
  evidence_anywhere: `${getTitleCase('Ability to invite external users for upload.')}`,
  manage_api_clients: `${getTitleCase('Ability to manage API clients.')}`
}
