import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartnerCollaborationService } from 'app/shared/api.services/partner-collaboration.service';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
@Component({
    selector: 'app-partner-collaboration',
    templateUrl: './partner-collaboration.component.html',
    styleUrls: ['./partner-collaboration.component.scss'],
    standalone: false
})
export class PartnerCollaborationComponent implements OnInit {
  _displayedColumns: string[] = ['srno', 'data', 'action'];
  _dataSource = [];
  isLoading: boolean = false;
  isActionLoading: boolean = false;
  sampleData = [{
    id: 1,
    collaboration_ref_id: 123,
    agency_ori_number: 'OAT121212',
  }];

  constructor(
    private snackBar: MatSnackBar,
    private partnerService: PartnerCollaborationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    of({}).pipe(delay(0), tap(() => this.loadCollaborationRequestDataSource())).subscribe();
  }

  onRefresh() {
    this.loadCollaborationRequestDataSource();
  }

  loadCollaborationRequestDataSource() {
    this.isLoading = true;
    this.partnerService.getCollaborationRequests().subscribe({
      error: (err) => this._errHandler(),
      next: (data) => {
        this.isLoading = false;
        this._dataSource = data; // localtest - use this.sampleData
      }
    });
  }

  onAcceptRequest(partner_collaboration_request_id: number) {
    this.isActionLoading = true;
    this.partnerService.submitCollaborationRequest(partner_collaboration_request_id, 'approve').subscribe({
      error: (err) => this._errHandler(),
      next: (data) => this._handleActionResponse(data)
    });
  }

  onRejectRequest(partner_collaboration_request_id: number) {

    this.dialog.open(ReasonDialog).afterClosed().subscribe(result => {
      let reason = result;
      if(!reason) return this.snackBar.open('Please enter reason for rejection', null, {
        duration: 3000,
      });
      this.isActionLoading = true;
      this.partnerService.submitCollaborationRequest(partner_collaboration_request_id, 'reject',reason).subscribe({
        error: (err) => this._errHandler(),
        next: (data) => this._handleActionResponse(data)
      });
    });
  }

  private _handleActionResponse = (resData: any) => {
    this.isActionLoading = false;
    if (resData.message)
      return this.snackBar.open(`${resData.message}`, null, {
        duration: 3000,
      });
    this.onRefresh()
  }

  private _errHandler() {
    this.isLoading = false; this.isActionLoading = false;
    this.snackBar.open('Something went wrong!', null, {
      duration: 3000,
    });
  }

}

@Component({
    selector: 'reason-dialog',
    templateUrl: 'reason-dialog.html',
    imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, FormsModule]
})
export class ReasonDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public reason: string) {}
}
