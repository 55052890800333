import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { ConfirmDialogOptions } from 'app/models/confirm_dialog_options';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent {
  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions,
    private formBuilder: UntypedFormBuilder,) {
    this.form = this.formBuilder.group({
      confirmation: ['', [Validators.required, this.confirmationValidator()]]
    });

    if (!data?.user_input) {
      this.form.disable();
    }
  }

  get confirmation(): AbstractControl {
    return this.form.get('confirmation');
  }

  private confirmationValidator() {
    return (control: UntypedFormControl) => {
      if (!this.data?.user_input || control.value.trim().toUpperCase() === this.data?.user_input.phrase) {
        return null;
      } else {
        return { 'confirmation_error': true }
      }
    };
  }

}
