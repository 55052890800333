export enum Events {
  AgencyCreated = 'agency.account.created',
  SelfSignedupAgencyCreated = 'agency.account.selfsignedup.created',
  AgencyDetailsUpdated = 'agency.account.edited',
  AgencyDeleted = 'agency.account.deleted',
  AgencyRestored = 'agency.account.restored',
  AgencyLicenseAdded = 'agency.account.license.added',
  AgencyLicenseUpdated = 'agency.account.license.edited',
  SelfSignedUpAgencyLicenseUpdated = 'agency.account.license.selfsignedup.edited',
  AgencyUserAdded = 'agency.account.user.added',
  SelfSignedupAgencyUserAdded = 'agency.account.user.selfsignedup.added',
  AgencyUserDeleted = 'agency.account.user.deleted',
  AgencyUserRestored = 'agency.account.user.restored',
  AgencyUserDetailsUpdated = 'agency.account.user.updated',
  AgencyUserPermissionUpdated = 'agency.account.userpermissions.updated',
  AgencyUserNotificationsUpdated = 'agency.account.usernotifications.updated',
  AgencyGroupCreated = 'agency.account.group.created',
  AgencyGroupUsersUpdated = 'agency.account.group.users_updated',
  AgencyGroupDetailsUpdated = 'agency.account.group.updated',
  AgencyGroupDeleted = 'agency.account.group.deleted',
  AgencyGroupArchived = 'agency.account.group.archived',
  AgencyGroupUnarchived = 'agency.account.group.unarchived',
  AgencySubscriptionProductUpdated = 'agency.account.product.updated',
  AgencyTagCreated = 'agency.account.tag.created',
  AgencyTagUpdated = 'agency.account.tag.updated',
  // API client events
  AgencyExternalClientCreated = 'agency.account.api_clients_external.created',
  AgencyExternalClientDeleted = 'agency.account.api_clients_external.deleted',
  AgencyExternalClientEdited = 'agency.account.api_clients_external.edited',
  // 2fa
  AgencyAdd2faEnforcement = 'agency.account.security.two_factor_enforce',
  AgencyRemove2faEnforcement = 'agency.account.security.two_factor_remove_enforcement',
  AgencyAccount2FAEnabled = 'agency.account.security.two_factor_enabled',
  AgencyAccount2FADisabled = 'agency.account.security.two_factor_disabled',
  UserEnabled2fa = 'agency.user.account.security.two_factor_enabled',
  UserDisabled2fa = 'agency.user.account.security.two_factor_disabled',

  UserLoginSuccess = 'agency.user.login.success',
  UserLoginFailure = 'agency.user.login.failed',
  UserLogout = 'agency.user.logout',
  UserAccountRecoveryEmailSent = 'agency.user.account.recovery.email.success',
  UserAccountRecoveryEmailNotSent = 'agency.user.account.recovery.email.error',
  UserAccountRecoveryEmailAccessed = 'agency.user.account.recovery.email.access',
  UserAccountRecoverySuccess = 'agency.user.account.recovery.success',
  UserAccountRecoveryFailure = 'agency.user.account.recovery.error',
  UserAccountActivationEmailSent = 'agency.user.account.activation.email.success',
  UserAccountActivationEmailNotSent = 'agency.user.account.activation.email.error',
  UserAccountActivationEmailAccessed = 'agency.user.account.activation.email.access',
  UserAccountActivated = 'agency.user.account.activation.success',
  UserAccountCreated = 'agency.user.account.created',

  CaseCreated = 'agency.case.created',
  CaseDetailsUpdated = 'agency.case.edited',
  CaseArchived = 'agency.case.archived',
  ArchivedCaseRestored = 'agency.case.archive_restored',
  CaseDeleted = 'agency.case.deleted',
  DeletedCaseRestored = 'agency.case.deleted_restored',
  CasesMerged = 'agency.case.merged',
  CasePrinted = 'agency.case.printed',
  CaseCollaboratorAdded = 'agency.case.collaborator.added',
  CaseCollaboratorRemoved = 'agency.case.collaborator.removed',
  CaseViewed = 'agency.case.viewed',
  CaseRestricted = 'agency.case.restricted',
  CaseAssigned = 'agency.case.assigned',
  CaseUnassigned = 'agency.case.unassigned',
  ExternalUserInvited = 'agency.case.external_user_invited',
  CaseFolderCreated = 'agency.case.folder.created',
  CaseFolderDeleted = 'agency.case.folder.deleted',
  CaseFolderUpdated = 'agency.case.folder.updated',
  CaseFolderRestored = 'agency.case.folder.restored',

  CaseTagAdded = 'agency.case.tag.added',
  CaseTagDeleted = 'agency.case.tag.deleted',
  FileTagAdded = 'agency.case.file.tag.added',
  FileTagRemoved = 'agency.case.file.tag.deleted',

  FileUploaded = 'agency.case.file.uploaded',
  ExternalFileUploaded = 'agency.case.file.uploaded_external',
  MaliciousFileFound = 'agency.case.file.found_malicious',
  FileScanSkipped = 'agency.case.file.scan_skipped',
  PUAFileFound = 'agency.case.file.found_pua',
  FileDetailsUpdated = 'agency.case.file.edited',
  FileArchived = 'agency.case.file.archived',
  ArchivedFileRestored = 'agency.case.file.archive_restored',
  FileDeleted = 'agency.case.file.deleted',
  DeletedFileRestored = 'agency.case.file.deleted_restored',
  SensitiveFileViewed = 'agency.case.file.sensitive.viewed',
  FilesSharedViaEmail = 'agency.case.file.shared.email',
  FilesSharedViaEmailDownloaded = 'agency.case.file.shared.email.downloaded',
  FilesSharedViaEmailViewed = 'agency.case.file.shared.email.viewed',
  ExternalFileShareLinkStatusUpdated = 'agency.case.file.shared.email.status.updated',
  ExternalFileShareLinkValidityUpdated = 'agency.case.file.shared.email.validity.updated',
  ExternalFileShareContentRemoved = 'agency.case.file.shared.email.content.removed',
  FilesDownloaded = 'agency.case.file.downloaded',
  FilesDownloadRequested = 'agency.case.file.download_requested',
  FilesSharedViaEmailDownloadRequested = 'agency.case.file.shared.email.download_requested',
  FilesCopied = 'agency.case.file.copied',
  FilesMoved = 'agency.case.file.moved',

  FilePurged = 'agency.case.file.purged',
  FolderPurged = 'agency.case.folder.purged',
  CasePurged = 'agency.case.purged',
  CaseDoNotDeleteEnabled = 'agency.case.do_not_delete_enabled',
  CaseDoNotDeleteDisabled = 'agency.case.do_not_delete_disabled',
  CaseEarlyPurgeSchedule = 'agency.case.purge_schedule_updated',
  FileFolderEarlyPurgeSchedule = 'agency.case.file.purge_schedule_updated',
  // Partner Onboarding Events, (by system action on partner interface)
  PartnerTokenSuccess = 'partner.token.success',
  PartnerTokenFailure = 'partner.token.failure',
  PartnerTokenRefreshSuccess = 'partner.token.refresh.success',
  PartnerTokenRefreshFailure = 'partner.token.refresh.failure',

  // Partner Agency Events
  PartnerEntityCreated = 'agency.account.partner.entity.created',
  PartnerEntityDeleted = 'agency.account.partner.entity.deleted',
  PartnerEntityPublcKeyUpdated = 'agency.account.partner.entity.public_key.updated',

  PartnerCollaborationRequestCreated = 'agency.account.partner.collaboration_request.created',
  PartnerCollaborationRequestApproved = 'agency.account.partner.collaboration_request.approved',
  PartnerCollaborationRequestRejected = 'agency.account.partner.collaboration_request.rejected',
  PartnerCollaborationRequestDeleted = 'agency.account.partner.collaboration_request.deleted',

  // Api Client Events
  ApiClientCreated = 'agency.account.api_client.created',
  ApiClientUpdated = 'agency.account.api_client.updated',
  ApiClientDeactivated = 'agency.account.api_client.deactivated',
  ApiClientTokenSuccess = 'agency.account.api_client.token.success',
  ApiClientTokenFailure = 'agency.account.api_client.token.failure',
  ApiClientInvalidCredentials = 'agency.account.api_client.invalid_credentials',
  ApiClientTokenRefreshSuccess = 'agency.account.api_client.token.refresh.success',
  ApiClientTokenRefreshFailure = 'agency.account.api_client.token.refresh.failure',

  ApiClientAgencyAccessed = 'agency.account.api_client.agency.accessed',
  ApiClientCasesFetched = 'agency.account.api_client.cases.fetched',
  ApiClientCaseEvidenceAccessed = 'agency.case.api_client.case.metadata.accessed',
  ApiClientCaseAccessed = 'agency.case.api_client.case.accessed',
  ApiClientFileDownloaded = 'agency.case.api_client.file.downloaded',
  ApiClientFileStreamed = 'agency.case.api_client.file.streamed',

  // Partner Events (by user's action on partner interface) 
  PartnerAgencyViewed = 'agency.account.partner.agency.viewed',
  PartnerCasesFetched = 'agency.account.partner.cases.fetched',
  PartnerCaseViewed = 'agency.case.partner.viewed',
  PartnerFileDownloaded = 'agency.case.partner.file.downloaded',
  PartnerUserLoginSuccess = 'agency.user.partner.login.success',
  PartnerUserLoginFailure = 'agency.user.partner.login.error',
};
