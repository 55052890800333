import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { APIService } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseUrl = APIService.Search;

  constructor(private http: HttpService, ) { }

  getSuggestions(q: string, type?: string) {
    const params: any = { q };
    if (type) {
      params.type = type;
    }
    return this.http.get(`${this.baseUrl}/autocomplete`, { params });
  }
}
