import { Events } from './../../models/events';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { CaseActivity } from 'app/models/case_activity';
import { eventsRenderer } from './event-renderer-utils';
import { UserPreferenceDescription } from 'app/app.util';
import { floor } from 'lodash';
import { environment } from 'environments/environment';

@Pipe({
    name: 'activityDetails',
    standalone: false
})
export class ActivityDetailsPipe implements PipeTransform {

  transform(event: CaseActivity, args?: any): any {
    switch (event.event_name) {
      case Events.AgencyCreated:
        return `Agency created`;
      case Events.SelfSignedupAgencyCreated:
        return `<br>Agency created via self-signup process<br>`;
      case Events.AgencyRestored:
        return `<p>${event.meta_data.user_name} restored the agency</p>`
      case Events.AgencyDeleted:
        return `<p>${event.meta_data.user_name} deleted the agency</p>`
      case Events.AgencyDetailsUpdated:
        const agencyChangesList = event.meta_data.changes;
        let agecyChangeText = '';
        agencyChangesList.forEach(changeObj => {
          if (changeObj.actual !== changeObj.expected) {
            let changedPropertyName: string = changeObj.name.replace(/_/g, ' ');
            let changedPropertyPreviousVal = changeObj.actual; let changedPropertyUpdatedVal = changeObj.expected;
            // for retention period value, format the text and values
            if (changedPropertyName === 'preferences.retention period in mins') {
              changedPropertyName = 'Retention period';
              changedPropertyPreviousVal = changeObj.actual ? this.getRetentionPeriod(changeObj.actual) : null;
              changedPropertyUpdatedVal = changeObj.expected ? this.getRetentionPeriod(changeObj.expected) : null;
            }
            if (changedPropertyName === 'preferences.purge enabled' || changedPropertyName === 'purge enabled') {
              changedPropertyName = 'Purge feature';
            }
            if (changeObj.actual && changeObj.expected) {
              agecyChangeText += `<br> -
                  ${changedPropertyName} changed from "${changedPropertyPreviousVal}" to "${changedPropertyUpdatedVal}"<br>`;
            } else if (!changeObj.actual) {
              if (changedPropertyName === 'Purge feature') agecyChangeText += `<br> - ${changedPropertyName} is enabled<br>`;
              else agecyChangeText += `<br> - ${changedPropertyName} changed to "${changedPropertyUpdatedVal}"<br>`;
            } else if (!changeObj.expected) {
              if (changedPropertyName === 'Purge feature') agecyChangeText += `<br> - ${changedPropertyName} is disabled<br>`;
              else agecyChangeText += `<br> - ${changedPropertyName} value deleted<br>`;
            }
          }
        });
        return `<br>${event.meta_data.user_name} updated agency details:<br> ${agecyChangeText}<br>`;
      case Events.AgencyLicenseAdded:
        return `License added`;
      case Events.AgencyLicenseUpdated:
        return `${event.meta_data.user_name} updated License details`;
      case Events.SelfSignedUpAgencyLicenseUpdated:
        let eventTextString = '';
        const eventChanges = event.meta_data.changes.filter(change => change.name === 'units');
        if (eventChanges.length) {
          eventChanges.forEach(change => {
            if (change.actual !== change.expected)
              eventTextString = `<br>Agency subscription license count updated from ${change.actual} to ${change.expected} <br><br>`;
            else eventTextString = `<br>Agency subscription license updated <br><br>`;
          });
        } else eventTextString = `<br>Agency subscription license updated <br><br>`;
        return eventTextString;
      case Events.AgencyUserAdded:
        return `User ${event.meta_data.new_user_name} added by ${event.meta_data.user_name}`;
      case Events.SelfSignedupAgencyUserAdded:
        return `User ${event.meta_data.new_user_name} added via self-signup process`;
      case Events.AgencyUserDeleted:
        return `User ${event.meta_data.deleted_user_name} removed by ${event.meta_data.user_name}`;
      case Events.AgencyUserRestored:
        return event.meta_data.restored_user_name ? `<br>${event.meta_data.user_name} restored the user ${event.meta_data.restored_user_name}<br><br>`
          : `<br>${event.meta_data.user_name} restored a user(Details not available)<br><br>`;
      case Events.AgencyUserDetailsUpdated:
        const userChangesList = event.meta_data.changes;
        let userChangeText = '';
        userChangesList.forEach(changeObj => {
          if (changeObj.actual !== changeObj.expected) {
            if (changeObj.name === 'role_id') {
              userChangeText += `<br> - user role changed from
              "${changeObj.actual === 4 ? 'Agency Admin' : 'Officer'}" to
              "${changeObj.expected === 4 ? 'Agency Admin' : 'Officer'}"<br>`;
            } else if (changeObj.actual && changeObj.expected) {
              userChangeText += `<br> -
                ${changeObj.name.replace(/_/g, ' ')} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
            } else if (!changeObj.actual) {
              userChangeText += `<br> - ${changeObj.name} changed to "${changeObj.expected}"<br>`;
            } else if (!changeObj.expected) {
              userChangeText += `<br> - ${changeObj.name} value deleted<br>`;
            }
          }
        });
        return `<br>${event.meta_data.user_name} updated the details of the user ${event.meta_data.detail}:<br> ${userChangeText}<br>`;
      case Events.AgencyUserPermissionUpdated:
        const userPermChangesList = event.meta_data.changes;
        let userPermChangesText = '';
        userPermChangesList.forEach(changeObj => {
          userPermChangesText += `<br> - ${changeObj.name} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
        });
        return `<br>${event.meta_data.user_name} updated the details of the user ${event.meta_data.detail}:<br> ${userPermChangesText}<br>`;
      case Events.AgencyUserNotificationsUpdated:
        const userNotifChangesList = event.meta_data.changes;
        let prefStatus;
        userNotifChangesList.forEach(changeObj => {
          if (changeObj.name === 'status') {
            prefStatus = changeObj.expected;
          }
        });
        if (event.meta_data.notification_name === UserPreferenceDescription.SharedCasesNotification) {
          return `<br>${event.meta_data.user_name} ${prefStatus === 1 ? 'enabled' : 'disabled'} ${event.meta_data.notification_name} notification for ${event.meta_data.detail}<br><br>`;
        } else {
          return `<br>${event.meta_data.user_name} ${prefStatus === 1 ? 'enabled' : 'disabled'} ${event.meta_data.notification_name}
            ${event.meta_data.notification_name === UserPreferenceDescription.DownloadZipping ? 'preference' : 'notification'}<br><br>`;
        }

      case Events.AgencyGroupCreated:
        const groupCreationEvent = event.meta_data.new_group_users ? `<br>${event.meta_data.user_name} created the group, ${event.meta_data.new_group_name} with ${event.meta_data.new_group_users}<br><br>`
          : `<br>${event.meta_data.user_name} created the group, ${event.meta_data.new_group_name}<br><br>`;
        return groupCreationEvent;
      case Events.AgencyGroupUsersUpdated:
        return `<br>${event.meta_data.user_name} updated the users of the group, ${event.meta_data.group_name}: <br><br>
          users: ${event.meta_data.changes[0].expected}<br><br>`;
      case Events.AgencyGroupDetailsUpdated:
        let change = '';
        event.meta_data.changes.forEach(changeObj => {
          if (changeObj.actual !== changeObj.expected) {
            change += `<br> - ${changeObj.name} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
          }
        });
        return `<br>${event.meta_data.user_name} updated group details:<br> ${change}<br>`;
      case Events.AgencyGroupDeleted:
        return `<br>${event.meta_data.user_name} deleted the group, ${event.meta_data.group_name}<br><br>`;
      case Events.AgencyGroupArchived:
        return `<br>${event.meta_data.user_name} archived the group, ${event.meta_data.group_name}<br><br>`;
      case Events.AgencyGroupUnarchived:
        return `<br>${event.meta_data.user_name} unarchived the group, ${event.meta_data.group_name}<br><br>`;
      case Events.AgencySubscriptionProductUpdated:
        let subscriptionUpdatedEventText = '';
        event.meta_data.changes.forEach(change => {
          if (change.actual !== change.expected && change.name === 'product')
            subscriptionUpdatedEventText = `<br>Agency subscription product updated from ${change.actual} to ${change.expected} <br><br>`;
          else subscriptionUpdatedEventText = `<br>Agency subscription updated <br><br>`;
        });
        return subscriptionUpdatedEventText;
      case Events.AgencyExternalClientCreated:
        return `
            <p>
              ${event.meta_data.user_name ?? ''} <b>created</b> a client: <br><br>
              Name - ${event.meta_data.externalApiClient?.name || 'N/A'}
              Client type - ${event.meta_data.externalApiClient?.client_type === 3 ? 'AXON' : 'N/A'}
            </p>
          `;
      case Events.AgencyExternalClientDeleted:
        return `
              <p>
                ${event.meta_data.user_name ?? ''} <b>deleted</b> a client ${event.meta_data?.name || ''}
              </p>
            `;
      case Events.AgencyExternalClientEdited:
        const clientDetails = event.meta_data.changes;
        let changes = '';
        clientDetails.forEach(changeObj => {
          const updatedValue = changeObj.name === 'pull_all_evidence' ? 'Fetch all evidence' : changeObj.name;
          if (changeObj.actual !== changeObj.expected) {
            if (changeObj.actual && changeObj.expected) {
              changes += `<br> -
                  ${updatedValue.replace(/_/g, ' ')} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
            } else if (!changeObj.actual) {
              changes += `<br> - ${updatedValue} changed to "${changeObj.expected}"<br>`;
            } else if (!changeObj.expected) {
              changes += `<br> - ${updatedValue} value deleted<br>`;
            }
          }
        });
        return `<p>${event.meta_data.user_name} <b>updated</b> the client ${event.meta_data.name || ''}, details:<br> ${changes}</p>`;

      case Events.AgencyTagCreated:
        return `<p>${event.meta_data.user_name} has <b>created</b> a tag "${event.meta_data.tag?.name || ''}".</p>`;
      case Events.AgencyTagUpdated:
        const { user_name, tag } = event.meta_data;
        const statusChange = event.meta_data.changes.find(change => change.name === "status");
        if (statusChange) {
          const action = statusChange.expected === 3 ? "archived" : "unarchived";
          return `<p>${user_name} <b>${action}</b> the tag "${tag.name || ''}".</p>`;
        }
        return `<p>${event.meta_data.user_name} has updated a tag ${event.meta_data.tag?.name || ''}</p>`;

      case Events.UserLoginSuccess:
        const with2fa = event.meta_data.with_2fa;
        return `${event.meta_data.user_name} logged in successfully ${with2fa ? 'with 2fa' : ''}`;
      case Events.UserLoginFailure:
        return `${event.meta_data.user_name} login failed`;
      case Events.UserLogout:
        return `${event.meta_data.user_name} logged out`;
      case Events.UserAccountRecoveryEmailSent:
        return `Account recovery email sent`;
      case Events.UserAccountRecoveryEmailNotSent:
        return `${event.meta_data.user_name} could not send account recovery email`;
      case Events.UserAccountRecoveryEmailAccessed:
        return `${event.meta_data.user_name} accessed recovery email link`;
      case Events.UserAccountRecoverySuccess:
        return `${event.meta_data.user_name} account recovered successfully`;
      case Events.UserAccountRecoveryFailure:
        return `${event.meta_data.user_name} could not recover account`;
      case Events.UserAccountCreated:
        return `${event.meta_data.user_name} account created`
      case Events.UserAccountActivationEmailSent:
        return `${event.meta_data.user_name} account activation email sent`;
      case Events.UserAccountActivationEmailNotSent:
        return `${event.meta_data.user_name} could not send account activation email`;
      case Events.UserAccountActivationEmailAccessed:
        return `${event.meta_data.user_name} accessed account activation email link`;
      case Events.UserAccountActivated:
        return `${event.meta_data.user_name}'s account activated`;

      case Events.CaseCreated:
        return `${event.meta_data.user_name} has created the case ${event.meta_data.case_name || ''}`;
      case Events.CaseDetailsUpdated:
        const changesList = event.meta_data.changes;
        let changeText = '';
        changesList.forEach(changeObj => {
          if (changeObj.actual !== changeObj.expected) {
            changeText += `<br> - ${changeObj.name} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
          }
        });
        return `<br>${event.meta_data.user_name} updated case details:<br> ${changeText}<br>`;
      case Events.CaseArchived:
        return `${event.meta_data.user_name} archived the case ${event.meta_data.case_name || ''}`;
      case Events.ArchivedCaseRestored:
        return `${event.meta_data.user_name} unarchived the case ${event.meta_data.case_name || ''}`;
      case Events.CaseDeleted:
        return `${event.meta_data.user_name} deleted the case ${event.meta_data.case_name || ''}`;
      case Events.DeletedCaseRestored:
        return `${event.meta_data.user_name} restored the case ${event.meta_data.case_name || ''}`;
      case Events.CasesMerged:
        if (event.meta_data.src_case_names) {
          let src_cases = '';
          event.meta_data.src_case_names.forEach((src_case, index) => {
            if (index !== event.meta_data.src_case_names.length - 1) {
              src_cases += `${src_case}, `;
            } else {
              src_cases += `${src_case}`;
            }
          });
          return `<br>${event.meta_data.user_name} created the case ${event.meta_data.case_name} by merging ${src_cases}<br><br>`;
        } else {
          return `<br>Case merge history is not available.<br><br>`;
        }
      case Events.CasePrinted:
        return `${event.meta_data.user_name} printed the case ${event.meta_data.case_name || ''}`;
      case Events.CaseCollaboratorAdded:
        if (event.meta_data.method === 'user_invited') {
          return `${event.meta_data.user_name} added a ${event.meta_data.collaborator_name} as a collaborator to the case ${event.meta_data.case_name || ''}`;
        } else if (event.meta_data.method === 'self_by_case_creation') {
          return `${event.meta_data.user_name} added self as a collaborator by opening the case ${event.meta_data.case_name || ''}`;
        } else if (event.meta_data.method === 'self_by_file_creation') {
          return `${event.meta_data.user_name} added self as a collaborator by uploading a file to the case ${event.meta_data.case_name || ''}`;
        } else if (event.meta_data.method === 'added_by_user') {
          return `${event.meta_data.user_name} added ${event.meta_data.collaborator_name} as a collaborator to the case ${event.meta_data.case_name || ''}`;
        } else {
          return `Collaborator added to the case ${event.meta_data.case_name || ''}`;
        }
      case Events.CaseCollaboratorRemoved:
        if (event.meta_data.method === 'removed_by_user') {
          return `${event.meta_data.user_name} remover a collaborator - ${event.meta_data.collaborator_name} from the case ${event.meta_data.case_name || ''}`;
        } else {
          return `${event.meta_data.user_name} removed a collaborator from the case ${event.meta_data.case_name || ''}`;
        }
      case Events.CaseViewed:
        return `<br>${event.meta_data.user_name} viewed the case ${event.meta_data.case_name || ''}<br><br>`;
      case Events.CaseAssigned:
        return `<br><span>${event.meta_data.user_name} assigned the case ${event.meta_data.case_name || ''} to
          ${event.meta_data.receiver_name || ''}</span><br><br>`;
      case Events.CaseUnassigned:
        return `<br><span>${event.meta_data.user_name} unassigned the case ${event.meta_data.case_name || ''} from
          ${event.meta_data.receiver_name || ''}</span><br><br>`;
      case Events.CaseRestricted:
        const caseRestrictStat = event.meta_data.changes[0];
        let restrictText = '';
        if (caseRestrictStat.expected === 'Unrestricted') {
          restrictText += `<br>${event.meta_data.user_name} removed restrictions for the case ${event.meta_data.detail || ''} from ${caseRestrictStat.actual}<br><br>`
        } else {
          restrictText += `<br>${event.meta_data.user_name} restricted the case ${event.meta_data.detail || ''} to ${caseRestrictStat.expected}<br><br>`
        }
        return `${restrictText}`;
      case Events.ExternalUserInvited:
        return `<br>${event.meta_data.user_name} sent an upload invitation to ${event.meta_data.recipient}
          for the case ${event.meta_data.case_name}<br><br>`;
      case Events.CaseFolderCreated:
        return `<br>${event.meta_data.user_name} created the folder, ${event.meta_data.folder_name} in the case, ${event.meta_data.case_name}<br><br>`;
      case Events.CaseFolderDeleted:
        if (event.meta_data.files && event.meta_data.files.length) {
          const deletedFileNames = event.meta_data.files.map(file => file.name).join(', ');
          return `<br>${event.meta_data.user_name} deleted the folder, ${event.meta_data.folder_name} in the case, ${event.meta_data.case_name} with files: <br>
              ${deletedFileNames}
            <br><br>`;
        } else {
          return `<br>${event.meta_data.user_name} deleted the folder, ${event.meta_data.folder_name} in the case, ${event.meta_data.case_name}
            <br><br>`;
        }

      case Events.CaseFolderUpdated:
        if (event.meta_data.changes) {
          const folderChangesList = event.meta_data.changes;
          let folderChangeText = '';
          folderChangesList.forEach(changeObj => {
            if (changeObj.actual !== changeObj.expected) {
              if (changeObj.actual && changeObj.expected) {
                folderChangeText += `<br> -
                    ${changeObj.name.replace(/_/g, ' ')} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
              } else if (!changeObj.actual) {
                folderChangeText += `<br> - ${changeObj.name} changed to "${changeObj.expected}"<br>`;
              } else if (!changeObj.expected) {
                folderChangeText += `<br> - ${changeObj.name} value deleted<br>`;
              }
            }
          });
          return `<br>${event.meta_data.user_name} updated the folder ${event.meta_data.folder_name} from the case ${event.meta_data.case_name || ''}, details:<br> ${folderChangeText}<br>`;
        } else {
          return `<br> ${event.meta_data.user_name} updated the folder ${event.meta_data.folder_name} (Details not available).<br><br>`;
        }
      case Events.CaseFolderRestored:
        if (event.meta_data.files && event.meta_data.files.length) {
          const restoredFileNames = event.meta_data.files.map(file => file.name).join(', ');
          return `<br> ${event.meta_data.user_name} restored the folder ${event.meta_data.folder_name} to the case ${event.meta_data.case_name} with files: <br>
              ${restoredFileNames}<br><br>`
        } else {
          return `<br> ${event.meta_data.user_name} restored the folder ${event.meta_data.folder_name} to the case ${event.meta_data.case_name}<br><br>`;
        }
      case Events.CasePurged:
        return `<br><span><br>Case ${event.meta_data.case_name} got <b>purged</b><br><br></span>`;
      case Events.CaseDoNotDeleteEnabled:
        const manualDNDEvent = `<br><span><br>${event.meta_data.user_name} has <b>enabled</b> "Do not Delete" for the case ${event.meta_data.detail || event.meta_data.case_name || ""}<br><br></span>`;
        const systemDNDEvents: any = {
          [Events.FilesSharedViaEmail]: `<br><span><br>System has <b>enabled</b> "Do not Delete" for the case ${event.meta_data.detail || event.meta_data.case_name || ""} because file / folder was shared via email.<br><br></span>`,
          [Events.CaseCollaboratorAdded]: `<br><span><br>System has <b>enabled</b> "Do not Delete" for the case ${event.meta_data.detail || event.meta_data.case_name || ""} because new collaborator got added to the case.<br><br></span>`
        };
        return systemDNDEvents[event.meta_data?.ref_event_name] || manualDNDEvent;
      case Events.CaseDoNotDeleteDisabled:
        return `<br><span><br>${event.meta_data.user_name} has <b>disabled</b> "Do not Delete" for case ${event.meta_data.detail || ""}<br><br></span>`;
      case Events.CaseEarlyPurgeSchedule:
        return `<br><span><br>${event.meta_data.user_name} has marked the case "${event.meta_data.detail || ""}" for <b>early purge</b><br><br></span>`;

      case Events.CaseTagAdded:
        return this.constructTagUpdateEvent(event, 'case');
      case Events.CaseTagDeleted:
        return this.constructTagUpdateEvent(event, 'case');
      case Events.FileTagAdded:
        return this.constructTagUpdateEvent(event, 'file');
      case Events.FileTagRemoved:
        return this.constructTagUpdateEvent(event, 'file');

      case Events.FileUploaded:
        return event.meta_data.folder_name ?
          `<br><span>${event.meta_data.user_name} uploaded a file ${event.meta_data.file_name || ''} to the folder ${event.meta_data.folder_name} of the case ${event.meta_data.case_name || ''}</span><br><br>` :
          `<br><span>${event.meta_data.user_name} uploaded a file ${event.meta_data.file_name || ''} to the case ${event.meta_data.case_name || ''}</span><br><br>`;
      case Events.ExternalFileUploaded:
        let details = `<br>Device: ${event.meta_data.useragent.family}(${event.meta_data.useragent.major || ''})<br>
                         OS: ${event.meta_data.useragent['os'].family}<br>
                         IP-Location: ${event.meta_data.ipaddress}<br>
        `;
        if (event?.meta_data?.scan_report) {
          details += `Scan report: ${event?.meta_data?.scan_report}`;
        }
        let eventText = `<p>
          ${event.meta_data.ext_user_name} uploaded a ${event?.meta_data?.scan_report ? `<b>malicious</b>` : ``} file ${event.meta_data.file_name || ''} ${event.meta_data.folder_name ? `to the folder ${event.meta_data.folder_name} of` : `to`}  the case ${event.meta_data.case_name}, details: <br>${details}
        </p>`;
        return eventText;
      case Events.MaliciousFileFound:
        return `<p>System has <b>removed</b> the file ${event.meta_data.file_name || ''} from the case ${event.meta_data.case_name || ''} due to detection of malicious content. The file will not be available in the case.<br><br>
        Uploaded by - ${event.meta_data.ext_user_name} <br>
        Scan report: : ${event?.meta_data?.scan_report}
        </p>`
      case Events.FileScanSkipped:
        return `<p>System has <b>skipped</b> scanning the file ${event.meta_data.file_name || ''} from the case ${event.meta_data.case_name || ''}.  Be cautious when viewing or downloading the file.<br><br> 
        Uploaded by - ${event.meta_data.ext_user_name}
        </p>`;
      case Events.PUAFileFound:
        return `<p>System has <b>detected</b> potentially unwanted application (PUA) content in the file ${event.meta_data.file_name || ''} from the case ${event.meta_data.case_name || ''}.  Be cautious when viewing or downloading the file.<br><br> 
        Uploaded by - ${event.meta_data.ext_user_name} <br>
        Scan report: : ${event?.meta_data?.scan_report}
        </p>`;
      case Events.SensitiveFileViewed:
        return `<p>${event.meta_data.user_name} has viewed the sensitive file ${event.meta_data.file_name || ''} from the case  ${event.meta_data.case_name || ''}</p>`;
      case Events.FileDetailsUpdated:
        if (event.meta_data.changes) {
          const fileChangesList = event.meta_data.changes;
          let fileChangeText = '';
          fileChangesList.forEach(changeObj => {
            if (changeObj.actual !== changeObj.expected) {
              if (changeObj.actual && changeObj.expected) {
                fileChangeText += `<br> -
                ${changeObj.name.replace(/_/g, ' ')} changed from "${changeObj.actual}" to "${changeObj.expected}"<br>`;
              } else if (!changeObj.actual) {
                fileChangeText += `<br> - ${changeObj.name} changed to "${changeObj.expected}"<br>`;
              } else if (!changeObj.expected) {
                fileChangeText += `<br> - ${changeObj.name} value deleted<br>`;
              }
            }
          });
          return `<br>${event.meta_data.user_name} updated file ${event.meta_data.file_name}, details:<br> ${fileChangeText}<br>`;
        } else {
          return `<br> ${event.meta_data.user_name} updated the file ${event.meta_data.file_name} (Details not available).<br><br>`;
        }
      case Events.FileArchived:
        return `${event.meta_data.user_name} archived the file ${event.meta_data.file_name || ''}`;
      case Events.ArchivedFileRestored:
        return `${event.meta_data.user_na1me} unarchive the file ${event.meta_data.file_name || ''}`;
      case Events.FilesSharedViaEmailDownloaded:
        if (event.meta_data.files?.length) {
          const eventText = `<br><span>${event.meta_data.user_name} has downloaded the following shared files from the case <u><i>${event.meta_data.case_name || ''}</i></u>
            (Download id: ${event.meta_data.download_id})<br><br>
            ${event.meta_data.files
              .map((file: any, index: number) => {
                return `${index + 1}. ${file.name}`;
              })
              .join(`<br>`)}</span><br><br>`;
          return eventText;
        } else if (event.meta_data.file_name) {
          return `<br><span>${event.meta_data.user_name} has downloaded the shared file ${event.meta_data.file_name} from the case <u><i>${event.meta_data.case_name || ''}</i></u> (Download id: ${event.meta_data.download_id})</span><br><br>`;
        } else {
          return `<br><span>${event.meta_data.user_name} has downloaded the shared files from the case <u><i>${event.meta_data.case_name || ''}</i></u> (Download id: ${event.meta_data.download_id})</span><br><br>`;
        }
      case Events.FilesSharedViaEmailViewed:
        if (event.meta_data.file_name) {
          return `<br><span>${event.meta_data.user_name} has viewed the shared file ${event.meta_data.file_name} from the case <u><i>${event.meta_data.case_name || ''}</i></u></span><br><br>`;
        } else {
          return `<br><span>${event.meta_data.user_name} has viewed the shared files from the case <u><i>${event.meta_data.case_name || ''}</i></u></span><br><br>`;
        }
      case Events.FilesDownloaded:
        if (event.meta_data.files?.length) {
          const eventText = `<br><span>${event.meta_data.user_name} has downloaded the following files from the case ${event.meta_data.case_name || ''}
              ${event.meta_data.download_id ? `(Download id: ${event.meta_data.download_id})` : ``}
              <br><br>
              ${event.meta_data.files
              .map((file: any, index: number) => {
                return `${index + 1}. ${file.name}`;
              })
              .join(`<br>`)}</span><br><br>`;
          return eventText;
        } else if (event.meta_data.file_name) {
          return `<br><span>${event.meta_data.user_name} has downloaded the file ${event.meta_data.file_name} from the case ${event.meta_data.case_name || ''} (Download id: ${event.meta_data.download_id})</span><br><br>`;
        } else return `<br><span>${event.meta_data.user_name} has downloaded files from the case ${event.meta_data.case_name || ''} (Download id: ${event.meta_data.download_id})</span><br><br>`;

      case Events.ExternalFileShareLinkStatusUpdated:
        const action = event.meta_data.changes && event.meta_data.changes[0].expected === '4' ? 'disabled' : 'enabled';
        let shareDetail = `share id - ${event.meta_data.share_id}`;
        if (event.meta_data.shared_with) shareDetail += `, shared with - ${event.meta_data.shared_with}`;
        return `<span>${event.meta_data.user_name} has <b>${action}</b> the share (${shareDetail}) from the case ${event.meta_data.case_name || ''}</span><br></br>`;

      case Events.ExternalFileShareLinkValidityUpdated:
        const from = event.meta_data.changes && event.meta_data.changes[0].actual ? this.getDate(event.meta_data.changes[0].actual) : '';
        const to = event.meta_data.changes && event.meta_data.changes[0].expected ? this.getDate(event.meta_data.changes[0].expected) : '';
        let shareMeta = `share id - ${event.meta_data.share_id}`;
        if (event.meta_data.shared_with) shareMeta += `, shared with - ${event.meta_data.shared_with}`;
        return `<span><br>${event.meta_data.user_name} has <b>updated</b> the share link validity (${shareMeta}) from the case ${event.meta_data.case_name || ''}<br><br>
        <b>from</b> ${from} <b>to</b> ${to} <br><br>
        </span>`;
      case Events.ExternalFileShareContentRemoved:
        const files = event.meta_data.files;
        const folders = event.meta_data.folders;
        let itemList = ''; let countText = ``;
        if (files.length) {
          countText += `${files.length} files`;
          itemList = `<b>Files:</b><br><br>
          ${files
              .map((file: any, index: number) => {
                return `${index + 1}. ${file.name}`;
              })
              .join(`<br>`)}
          `;
        }
        if (folders.length) {
          countText += files.length > 0 ? ` and ${folders.length} folders` : `${folders.length} folders`;
          itemList += files.length > 0 ? `<br><br><b>Folders:</b><br><br>
          ${folders
              .map((folders: any, index: number) => {
                return `${index + 1}. ${folders.name}`;
              })
              .join(`<br>`)}
          ` : `<b>Folders:</b><br><br>
          ${folders
            .map((folders: any, index: number) => {
              return `${index + 1}. ${folders.name}`;
            })
            .join(`<br>`)}
          `;
        }
        return `<span><br>${event.meta_data.user_name} has <b>removed</b> ${countText} in the share (shareId - ${event.meta_data.share_id}, shared_with: ${event.meta_data.shared_with})
        from the case ${event.meta_data.case_name || ''}<br><br>
        ${itemList}<br><br>
        </span><br><br>`

      case Events.FilePurged:
        const purgedFiles = event.meta_data.files;
        if (purgedFiles?.length > 0) {
          const eventText = `<p><span>Following ${purgedFiles.length} file(s) were <b>purged</b> from the case <u><i>${event.meta_data.case_name || ''}</i></u><br><br>
            ${purgedFiles.map((file: any, index: number) => {
            return `${index + 1}. ${file.file_name} from path: ${file.path || 'N/A'}`;
          })
              .join(`<br>`)}</span><br></p>`;
          return eventText;
        }
        return `<br><span><br>File ${event.meta_data.file_name} <b>purged</b> from the case <u><i>${event.meta_data.case_name || ""}</i></u><br><br></span>`;
      case Events.FolderPurged:
        const purgedFolders = event.meta_data.folders;
        if (purgedFolders?.length > 0) {
          const eventText = `<p><span>Following ${purgedFolders.length} folder(s) were <b>purged</b> from the case <u><i>${event.meta_data.case_name || ''}</i></u><br><br>
            ${purgedFolders.map((folder: any, index: number) => {
            return `${index + 1}. ${folder.folder_name} from path: ${folder.path || 'N/A'}`;
          })
              .join(`<br>`)}</span><br></p>`;
          return eventText;
        }
        return `<br><span><br>Folder ${event.meta_data.folder_name} <b>purged</b> from the case <u><i>${event.meta_data.case_name || ""}</i></u><br><br></span>`;

      case Events.FileFolderEarlyPurgeSchedule:
        return eventsRenderer[Events.FileFolderEarlyPurgeSchedule](event);


      case Events.FilesDownloadRequested:
      case Events.FilesSharedViaEmail:
      case Events.FilesSharedViaEmailDownloadRequested:
      case Events.FileDeleted:
      case Events.FilesCopied:
      case Events.FilesMoved:
      case Events.DeletedFileRestored: {
        let renderKey = event.event_name as string
        if (event.meta_data.version >= 1) renderKey += `_v${event.meta_data.version}`
        return eventsRenderer[renderKey](event)
      }
      case Events.AgencyAccount2FAEnabled:
        return `<br><span>${event.meta_data.user_name} has <b>enabled</b> 2FA.</span><br>`;
      case Events.AgencyAccount2FADisabled:
        const user: string = event.meta_data.of_user_name ? `${event.meta_data.of_user_name}'s` : 'personal';
        return `<br><span>${event.meta_data.user_name} has <b>disabled</b> ${user} 2FA</span><br>`;

      case Events.PartnerTokenSuccess: {
        return `<br><span>${event.meta_data.partner} has successfully created auth token</span><br>`;
      }
      case Events.PartnerTokenFailure: {
        return `<br><span>${event.meta_data.partner} has failed to create auth token</span><br>`;
      }
      case Events.PartnerTokenRefreshSuccess: {
        return `<br><span>${event.meta_data.partner} has successfully refreshed auth token</span><br>`;
      }
      case Events.PartnerTokenRefreshFailure: {
        return `<br><span>${event.meta_data.partner} has failed to refresh auth token</span><br>`;
      }

      case Events.PartnerEntityCreated: {
        return `<br><span>${event.meta_data.partner} has successfully created an entity with ori number ${event.meta_data.name}</span><br>`;
      }

      case Events.PartnerEntityDeleted: {
        return `<br><span>${event.meta_data.partner} has deleted an entity with ori number ${event.meta_data.name}</span><br>`;
      }

      case Events.PartnerEntityPublcKeyUpdated: {
        return `<br><span>${event.meta_data.partner} has updated public key of entity with ori number ${event.meta_data.name}</span><br>`;
      }

      case Events.PartnerCollaborationRequestCreated: {
        return `<br><span>${event.meta_data.partner} has successfully created collaboration request between partner entity with ori number ${event.meta_data.partner_ori_number} and ori number ${event.meta_data.ori_number}</span><br>`;
      }

      case Events.PartnerCollaborationRequestDeleted: {
        return `<br><span>${event.meta_data.partner} has deleted collaboration request between partner entity with ori number ${event.meta_data.partner_ori_number} and ori number ${event.meta_data.ori_number}</span><br>`;
      }

      case Events.PartnerCollaborationRequestApproved: {
        return `<br><span>${event.meta_data.user_name} has approved collaboration request created by ${event.meta_data.partner} between partner entity with ori number ${event.meta_data.partner_ori_number} and ori number ${event.meta_data.ori_number}</span><br>`;
      }

      case Events.PartnerCollaborationRequestRejected: {
        return `<br><span>${event.meta_data.user_name} has rejected collaboration request created by ${event.meta_data.partner} between partner entity with ori number ${event.meta_data.partner_ori_number} and ori number ${event.meta_data.ori_number}</span><br>`;
      }

      case Events.PartnerCasesFetched: {
        if (event.meta_data.cases) {
          return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has fetched ${event.meta_data.cases.length > 1 ? 'cases' : 'case'} with case ${event.meta_data.cases.length > 1 ? 'numbers' : 'number'}} ${event.meta_data.cases.map((c) => c.name).join(', ')} on ${event.meta_data.partner} partner interface</span><br>`;
        }
        return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has fetched cases on ${event.meta_data.partner} partner interface</span><br>`;
      }

      case Events.PartnerAgencyViewed: {
        return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has viewed agency metadata on ${event.meta_data.partner} partner interface</span><br>`;
      }

      case Events.PartnerCaseViewed: {
        if (event.meta_data.view_type === 'evidence') {
          return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has viewed list of files/folder within case number ${event.meta_data.case_name}  on ${event.meta_data.partner} partner interface</span><br>`;
        }
        if (event.meta_data.view_type === 'metadata') {
          return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has viewed metadata of case with case number ${event.meta_data.case_name}  on ${event.meta_data.partner} partner interface</span><br>`;
        }
        if (event.meta_data.view_type === 'search') {
          return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has searched files with search string '${event.meta_data.search_string}' within case number ${event.meta_data.case_name} on ${event.meta_data.partner} partner interface</span><br>`;
        }
        return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has viewed case with case number ${event.meta_data.case_name}  on ${event.meta_data.partner} partner interface</span><br>`;
      }

      case Events.PartnerFileDownloaded: {
        return `<br><span>${event.meta_data.partner_user_ref} from partner entity with ori number ${event.meta_data.partner_ori_number} has downloaded file with file name ${event.meta_data.file_name} on ${event.meta_data.partner} partner interface</span><br>`;
      }

      case Events.PartnerUserLoginSuccess: {
        return `<br><span>${event.meta_data.user_name} has logged in successfully on ${event.meta_data.partner} partner interface with OTP based authentication</span><br>`;
      }

      case Events.PartnerUserLoginFailure: {
        return `<br><span>${event.meta_data.user_name} has failed to log in on ${event.meta_data.partner} partner interface with OTP based authentication</span><br>`;
      }
      case Events.ApiClientTokenSuccess: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has successfully created auth token</span><br>`;
      }

      case Events.ApiClientTokenFailure: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has failed to create auth token</span><br>`;
      }

      case Events.ApiClientTokenRefreshSuccess: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has successfully refreshed auth token</span><br>`;
      }

      case Events.ApiClientTokenRefreshFailure: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has failed to refresh auth token</span><br>`;
      }

      case Events.ApiClientInvalidCredentials: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has failed to authenticate with invalid credentials</span><br>`;
      }

      case Events.ApiClientFileDownloaded: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has downloaded file with file name ${event.meta_data.file_name} from case ${event.meta_data.case_name}</span><br>`;
      }

      case Events.ApiClientFileStreamed: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has streamed file with file name ${event.meta_data.file_name} from case ${event.meta_data.case_name}</span><br>`;
      }

      case Events.ApiClientDeactivated: {
        return `<br><span>${event.meta_data.user_name} has deactivated the api client</span><br>`;
      }

      case Events.ApiClientCreated: {
        return `<br><span>${event.meta_data.user_name} has created the api client</span><br>`;
      }

      case Events.ApiClientCaseEvidenceAccessed: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has fetched evidence list of case with case number ${event.meta_data.case_name}</span><br>`;
      }

      case Events.ApiClientCaseAccessed: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has accessed case with case number ${event.meta_data.case_name}</span><br>`;
      }

      case Events.ApiClientCasesFetched: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has fetched cases list</span><br>`;
      }

      case Events.ApiClientAgencyAccessed: {
        return `<br><span>Client Id: ${event.meta_data.client_id} has accessed agency details</span><br>`;
      }

      default:
        return event.event_name;
    }
  }

  getDate(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  private getRetentionPeriod(retentionPeriodInMins: number): string {
    const MINS_TO_DAYS_MULTIPLIER = 1440;
    const retentionPeriodInDays = floor(retentionPeriodInMins / MINS_TO_DAYS_MULTIPLIER);
    if (retentionPeriodInDays === 1) return `1 day`;
    return `${retentionPeriodInDays} days`;
  }

  constructTagUpdateEvent(event: CaseActivity, eventType: string) {
    const { user_name, case_name, changes, file_name } = event.meta_data;
    const addedTags = changes.addedTags?.map(tag => tag.name) || [];
    const removedTags = changes.removedTags?.map(tag => tag.name) || [];

    let eventSentence = `${user_name} updated the tags of the case <u><i>${case_name || ''}</i></u>: <br><br>`;
    if (eventType === 'file') eventSentence = `${user_name} updated the tags of the file ${file_name || ''} from the case <u><i>${case_name || ''}</i></u>: <br><br>`;

    if (addedTags.length > 0) {
      eventSentence += `<b>Added</b>: ${addedTags.join(', ')}`;
    }

    if (addedTags.length > 0 && removedTags.length > 0) {
      eventSentence += `<br>`;
    }

    if (removedTags.length > 0) {
      eventSentence += `<b>Removed</b>: ${removedTags.join(', ')}`;
    }

    return `<p>${eventSentence}</p>`;
  }
}
