import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
    standalone: false
})
export class KeysPipe implements PipeTransform {

  transform(obj, args: string[]): any {
    const keys = [];
    for (const key in obj) {
      keys.push({key: key, value: obj[key]});
    }
    return keys;
  }
}
