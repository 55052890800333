<div mat-dialog-title>
  <span>{{ isEditAction ? "Edit User" : "Create User" }}</span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<form class="user-form" [formGroup]="userForm" (submit)="onSubmit()">
  <mat-dialog-content>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input required matInput placeholder="First Name" formControlName="first_name">
      </mat-form-field>
      <mat-form-field class="width-100">
        <input required matInput placeholder="Last Name" formControlName="last_name">
      </mat-form-field>
    </div>
    <mat-form-field class="width-100">
      <input required matInput placeholder="Email Address" type="email" formControlName="email_address">
    </mat-form-field>
    <div class="confirmation" *ngIf="confirmation.enabled">
      <mat-hint>
        <p>{{'If the user uses a mobile app, they should upload all their files before updating the email address.' |
          titlecase}}</p>
        <p>Please type <span class="phrase">CONFIRM</span> to proceed.</p>
      </mat-hint>
      <mat-form-field class="width-100">
        <input required matInput formControlName="confirmation" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input matInput placeholder="Mobile Number" type="number" formControlName="mobile_number">
        <mat-hint class="form-error" *ngIf="userForm.hasError('invalidMobileNumber')" color="danger">
          <mat-icon>close</mat-icon>
          Please enter a 10-digit number without special characters eg: '-' ')' '(' or spaces.
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="width-100">
        <input matInput placeholder="Alternate Contact" formControlName="alt_contact">
        <mat-hint class="form-error" *ngIf="userForm.hasError('invalidAltNumber')" color="danger">
          <mat-icon>close</mat-icon>
          Please enter a 10-digit number without special characters eg: '-' ')' '(' or spaces.
        </mat-hint>
      </mat-form-field>
    </div>

    <div style="padding-bottom: 14px;" class="role-selection">
      <span style="color: #717171; font-size: 13px; display: block; margin-bottom: 5px;">User Role*</span>
      <mat-radio-group formControlName="role" required>
        <mat-radio-button #roleOption *ngFor="let type of (userRoles | keys)" [value]="type.value"
          (change)="onRoleSelection($event)">
          {{ type.key }}&nbsp;&nbsp;
        </mat-radio-button>
        <mat-radio-button value="9" [disabled]="!isModified" [checked]="isModified">Modified
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field class="width-100" *ngIf="permissions.length > 0">
      <mat-label>User Permissions</mat-label>
      <mat-chip-grid #chipGrid aria-label="Permissions">
        <mat-chip-row *ngFor="let permission of permissions" (removed)="remove(permission)" selectable=true>
          {{permission.desc}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input disabled [matChipInputFor]="chipGrid" />
      </mat-chip-grid>
    </mat-form-field>

    <div class="available-permissions"
      *ngIf="isEditAction ? allPermissions.length > 0 : (allPermissions.length > 0 && permissionChange)">
      <div class="available-permissions-text">Available Permissions</div>
      <mat-accordion [multi]="true">
        <mat-expansion-panel *ngIf="login_permissions.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title style="color: grey;">Access Permissions</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #loginPermissions dense
            (selectionChange)="onPermissionSelection($event, loginPermissions.selectedOptions.selected, 'login')"
            style="overflow: auto; height: 120px;">
            <mat-list-option *ngFor="let available of login_permissions" [value]='available' style="font-weight: 500;"
              disabled="{{available.status === Status.INACTIVE}}"
              matTooltip="{{available.status === Status.INACTIVE ? 'Your Agency Has Not Subscribed To This Feature' : ''}}">
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.desktop_login"
                *ngIf="available.name === CasePermissions.DESKTOP_LOGIN">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.cloud_login" *ngIf="available.name === CasePermissions.CLOUD_LOGIN">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.mobile_login" *ngIf="available.name === CasePermissions.MOBILE_LOGIN">
                info
              </mat-icon>
              {{available.desc}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="case_Permissions.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title style="color: grey;">Case Permissions</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #casePermissions dense
            (selectionChange)="onPermissionSelection($event, casePermissions.selectedOptions.selected, 'case')"
            style="overflow: auto; height: 200px;">
            <mat-list-option *ngFor="let available of case_Permissions" [value]='available' style="font-weight: 500;"
              disabled="{{available.status === Status.INACTIVE}}"
              matTooltip="{{available.status === Status.INACTIVE ? 'Your Agency Has Not Subscribed To This Feature' : ''}}">
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_upload" *ngIf="available.name === CasePermissions.CASE_UPLOAD">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_download"
                *ngIf="available.name === CasePermissions.CASE_DOWNLOAD">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_delete"
                *ngIf="available.name === CasePermissions.DELETE_RESTORE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_archive"
                *ngIf="available.name === CasePermissions.ARCHIVE_UNARCHIVE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_print" *ngIf="available.name === CasePermissions.CASE_PRINT">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_merge" *ngIf="available.name === CasePermissions.CASE_MERGE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_restrict"
                *ngIf="available.name === CasePermissions.RESTRICT_CASE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_edit" *ngIf="available.name === CasePermissions.CASE_EDIT">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.evidence_anywhere"
                *ngIf="available.name === CasePermissions.EVIDENCE_ANYWHERE">
                info
              </mat-icon>
              {{available.desc}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="sharing_Permissions.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title style="color: grey;">Sharing Permissions</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #sharePermissions dense
            (selectionChange)="onPermissionSelection($event, sharePermissions.selectedOptions.selected, 'share')"
            style="overflow: auto; height: 120px;">
            <mat-list-option *ngFor="let available of sharing_Permissions" [value]='available'
              style="font-weight: 500;">
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.view_shared_cases"
                *ngIf="available.name === CasePermissions.VIEW_SHARED_CASE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_share_agency"
                *ngIf="available.name === CasePermissions.CASE_SHARE_AGENCY">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.case_share_via_email"
                *ngIf="available.name === CasePermissions.CASE_SHARE_EMAIL">
                info
              </mat-icon>
              {{available.desc}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="admin_Permissions.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title style="color: grey;">Admin Permissions</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #adminPermissions dense
            (selectionChange)="onPermissionSelection($event, adminPermissions.selectedOptions.selected, 'admin')"
            style="overflow: auto; height: 120px;">
            <mat-list-option *ngFor="let available of admin_Permissions" [value]='available' style="font-weight: 500;">
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.agency_update"
                *ngIf="available.name === CasePermissions.AGENCY_UPDATE">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.user_management"
                *ngIf="available.name === CasePermissions.USER_MANAGEMENT">
                info
              </mat-icon>
              <mat-icon matListItemIcon class="info-icon" matTooltipClass="tooltip"
                [matTooltip]="PermissionInfoTexts.super_admin" *ngIf="available.name === CasePermissions.SUPER_ADMIN">
                info
              </mat-icon>
              {{available.desc}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-dialog-content>

  <!-- <mat-dialog-actions align="end" *ngIf="!userForm.pristine || (permissionChange && permissions.length > 0)">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button [disabled]="isEditAction ? false : !userForm.valid || userForm.pristine" type="submit"
      color="primary">{{ isEditAction ? "SAVE" : "CREATE" }}</button>
  </mat-dialog-actions> -->
  <mat-dialog-actions align="end" *ngIf="!userForm.pristine || (permissionChange && permissions.length > 0)">
    <button mat-button mat-dialog-close>
      CANCEL
    </button>
    <button mat-button [disabled]="!userForm.valid" type="submit" color="primary">
      {{ isEditAction ? "SAVE" : "CREATE" }}
    </button>
  </mat-dialog-actions>
</form>