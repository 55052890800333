<h2 mat-dialog-title>Please add a reason for rejection</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field style="width: 100%;">
        <mat-label>Reason</mat-label>
        <textarea style="height: auto; width: 100%;" rows="3" matInput [(ngModel)]="reason" name="reason" required></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="reason" cdkFocusInitial>Submit</button>
</mat-dialog-actions>
