<div mat-dialog-title>
  <span>{{ isEditAction ? "Edit Agency" : "Create Agency" }}</span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<form class="agency-form" [formGroup]="agencyForm" (submit)="onSubmit()" *ngIf="agency || !isEditAction">
  <mat-dialog-content>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input required matInput placeholder="Name" formControlName="name">
      </mat-form-field>
      <mat-form-field class="width-100">
        <input matInput placeholder="ORI" formControlName="ori_number">
        <mat-hint>eg: PA0040100, TXDPD0000, PA004023C</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field class="width-100">
      <mat-select placeholder="Agency Type" formControlName="type" required>
        <mat-option *ngFor="let type of (agencyTypes | keys)" [value]="type.value">
          {{ type.key }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="width-100">
      <mat-chip-grid #agencyList>
        <mat-chip-row *ngFor="let agency of selectedAgencies; let i = index;" (removed)="selectedAgencies.splice(i, 1)"
          selected>
          {{agency.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input matInput #agencyInput placeholder="Choose agency" formControlName="collaborator_search"
          [matAutocomplete]="auto" [matChipInputFor]="agencyList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          matChipInputAddOnBlur="true">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
          (optionSelected)="addCollaboratingAgency($event)">
          <cdk-virtual-scroll-viewport itemSize="50" class="agencies-viewport">
            <ng-container *cdkVirtualFor="let agency of filteredAgencies | async">
              <mat-option [value]="agency">{{agency.name}}
                <span class="ori-info">
                  <span *ngIf="agency.ori_number">(ORI - {{agency.ori_number}})</span>
                  <span *ngIf="!agency.ori_number">(ORI - N/A)</span>
                </span>
              </mat-option>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
      </mat-chip-grid>
    </mat-form-field>
    <mat-form-field class="width-100">
      <input required matInput autocorrect="off" autocapitalize="off" placeholder="Address Line 1"
        formControlName="address_line1" #address_autocomplete>
    </mat-form-field>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input matInput placeholder="Address Line 2" formControlName="address_line2">
      </mat-form-field>
      <mat-form-field class="width-100">
        <input required matInput placeholder="City" formControlName="city">
      </mat-form-field>
    </div>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input required matInput placeholder="State" formControlName="state">
      </mat-form-field>
      <mat-form-field class="width-100">
        <input required matInput placeholder="Zipcode" formControlName="zipcode">
      </mat-form-field>
    </div>
    <div class="flex-row space-around gap-10" formGroupName="preferences" *ngIf="isEditAction">
      <div [fxFlex]="90">
        <p>Desired audio file format</p>
      </div>
      <mat-select formControlName="default_audio_format" placeholder="Select Format" style="width: 30%;">
        <mat-option *ngFor="let format of (supportedAudioFormats | keys)" [value]="format.value">
          {{ format.key }}
        </mat-option>
      </mat-select>
    </div>

    <div class="flex-row space-around gap-10 auth-setting">
      <div class="slide-toggle-container flex-50">
        <mat-slide-toggle formControlName="is_email_auth">Email Authentication</mat-slide-toggle>
        <mat-hint class="slide-toggle-hint">
          Allow users to login with email and password
        </mat-hint>
      </div>
      <div class="slide-toggle-container flex-50">
        <mat-slide-toggle formControlName="is_ad_auth">Active Directory Authentication</mat-slide-toggle>
        <mat-hint class="slide-toggle-hint">
          Allow users to login with Microsoft Active Directory
        </mat-hint>
      </div>
    </div>
    <div *ngIf="authValidationError" class="error-message" style="color: red; margin-top: 8px; font-size: 12px;">
      {{ authValidationError }}
    </div>

    <mat-form-field class="width-100" *ngIf="selectedPremiumFeatures && selectedPremiumFeatures.length">
      <mat-panel-title style="margin-bottom: 15px;">Agency Premium Features</mat-panel-title>
      <mat-chip-grid #chipGrid aria-label="Feature selection">
        <mat-chip-row *ngFor="let feat of selectedPremiumFeatures" (removed)="remove(feat)" selectable=true>
          {{feat.desc}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input disabled [matChipInputFor]="chipGrid" />
      </mat-chip-grid>
    </mat-form-field>

    <div class="premium-features" *ngIf="premiumFeatures && premiumFeatures.length">
      <!-- <div class="premium-features-text">Premium Features</div> -->
      <mat-accordion [multi]="true">
        <mat-expansion-panel style="box-shadow: none;" [expanded]="true">
          <mat-expansion-panel-header style="position: relative; right: 24px;">
            <mat-panel-title>Premium Features</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #premiumFeats dense
            (selectionChange)="onPremiumFeatSelection($event, premiumFeats.selectedOptions.selected)"
            style="overflow: auto; height: 120px;">
            <mat-list-option *ngFor="let available of premiumFeatures" [value]='available'>
              {{available.desc}}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-dialog-content>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-dialog-actions align="end" *ngIf="loading === false">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button [disabled]="!agencyForm.valid" type="submit" color="primary">{{ isEditAction ? "SAVE" : "CREATE"
      }}</button>
  </mat-dialog-actions>
</form>