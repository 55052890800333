import { Pipe, PipeTransform } from '@angular/core';
import {ActivityType} from '../../models/activity';

@Pipe({
    name: 'activityName',
    standalone: false
})
export class ActivityNamePipe implements PipeTransform {

  transform(name: string, args?: any): any {
   return ActivityType[name] || name;
  }
}
