<div mat-dialog-title>
  <span>Product Settings</span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isDataChanging"></mat-progress-bar>
<div mat-dialog-content>
  <mat-card>
    <div align="center" style="margin: 20px;" *ngIf="isLoading">
      <mat-spinner color="primary" diameter="60"></mat-spinner>
    </div>
    <div class="settings" *ngIf="!isLoading">
      <div class="app-email" style="margin-bottom: 30px;">
        <mat-form-field class="width-100">
          <mat-select #select placeholder="Choose App Email Provider" value={{appEmailProvider}}
            (selectionChange)="onAppEmailerSelection($event)">
            <mat-option value="ses">Amazon SES</mat-option>
            <mat-option value="postmark">Postmark</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="notif-email">
        <mat-form-field class="width-100">
          <mat-select #select placeholder="Choose Notification Email Provider" value={{notificationEmailProvider}}
            (selectionChange)="onNotificationEmailerSelection($event)">
            <mat-option value="ses">Amazon SES</mat-option>
            <mat-option value="postmark">Postmark</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div style="margin-top: 15px;">
        <div class="downtime-notification-text" style="margin-bottom: 7px; font-weight: 500;">Server Down Alert</div>
        <form name="caseFilterForm" [formGroup]="serverForm">
          <mat-form-field class="width-50">
            <input matInput type="datetime-local" placeholder="From..." formControlName="from"
              [max]="serverForm.value.to || today" />
          </mat-form-field>
          <mat-form-field class="width-50">
            <input matInput type="datetime-local" placeholder="...To" formControlName="to" [min]="serverForm.value.from"
              [max]="today" />
          </mat-form-field>
          <div class="downtime-notification">
            <div class="downtime-notification-text">Toggle Alert</div>
            <div>
              <mat-slide-toggle [checked]="serverStatusDown" color="primary" (change)="onToggleChange($event)"
                [disabled]="!serverForm.valid">
              </mat-slide-toggle>
            </div>
          </div>
        </form>
      </div> -->
    </div>
  </mat-card>
</div>