import { APIService } from 'app/app.constants';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PremiumService {
  private baseUrl = APIService.PremiumFeatures;

  constructor(private http: HttpService) { }

  getAll() {
    return this.http.get(`${this.baseUrl}`).pipe(map(res => {
      return res['data'];
    }));
  }
}
