import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'licenseProductName',
    standalone: false
})
export class LicenseProductNamePipe implements PipeTransform {

  transform(licenseType: string): string {
    const products = {
      'icf': 'iCrimeFighter',
      'ats': 'At-Scene',
      'sw': 'CloudScape'
    };
    return products[licenseType] ?? 'iCrimeFighter';
  }

}
