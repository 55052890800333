import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { throwError } from 'rxjs';
import { extend } from 'lodash';
import { License } from '../../models/license';
import { LicenseService } from '../../shared/api.services/license.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { SessionStorage } from 'ngx-webstorage';
import ms from 'ms';

@Component({
    selector: 'app-license-create',
    templateUrl: './license-create.component.html',
    styleUrls: ['./license-create.component.scss'],
    standalone: false
})
export class LicenseCreateComponent implements OnInit {
  @SessionStorage('isLoading', false) loading;
  today = new Date();
  form: UntypedFormGroup;
  license: License;
  licenseTypes = {
    'iCrimeFighter': 'icf',
    'At-Scene': 'ats',
    'New Hampshire': 'nh',
    'CloudScape': 'sw'
  };
  isEditAction = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LicenseCreateComponent>,
    private router: Router,
    private licenseService: LicenseService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,) { }

  ngOnInit() {
    if (this.data && this.data.id) { // edit action
      this.isEditAction = true;
      // this.license = this.data.license;
      // this.initLicenseForm();

      setTimeout(() => {
        const license_id: string = this.data.license ? this.data.license.id : this.data.id;
        this.licenseService.load(license_id).subscribe(lic => {
          if (!lic) {
            return this.dialogRef.close();
          }
          this.license = this.data.license;
          this.initLicenseForm();
        });
      });
    } else { // create action
      this.initLicenseForm();
    }
  }

  private initLicenseForm() {
    this.form = this.formBuilder.group({
      name: [(this.license && this.license.name) || 'New License'],
      desc: [(this.license && this.license.desc) || '', null],
      valid_from: [(this.license && this.license.valid_from) || this.today, this.dateValidator],
      valid_until: [(this.license && this.license.valid_until) || '', this.dateValidator],
      units: [(this.license && this.license.units) || 1, [Validators.required, Validators.pattern(/\d+/), Validators.min(1)]],
      type: [(this.license && this.license.type) || '', [Validators.required]],
      agency_id: [this.data.agencyId],
    });

    // this.form.get('valid_from').valueChanges.pipe(
    //   debounceTime(100),
    //   tap((input: any) => {
    //     console.log(ms(input));
    //   })
    // ).subscribe();
  }

  dateValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        return { 'dateInvalid': true }
      };
      return null;
    }
  }

  onSubmit() {
    const formValue = this.form.getRawValue();
    const licenseParams = extend({}, formValue, {

    });

    if (this.isEditAction) {
      licenseParams.id = this.license.id;
    }

    this.licenseService[this.isEditAction ? 'update' : 'create'](licenseParams).pipe(catchError((error: Response) => {
      this.snackBar.open('Something went wrong!', null, {
        duration: 2000,
      });
      return throwError(error);
    })).subscribe((res: any) => {
      this.dialogRef.close({ licenseId: res.data.id });
      this.snackBar.open('License saved successfully', this.isEditAction ? null : 'ADD USERS', {
        duration: 3000,
      }).onAction().subscribe(() => {
        // return this.router.navigate(['/app/agencies/detail', res.data.agency_id]);
        this.router.navigateByUrl(`/app/agencies/users/${res.data.agency_id}`);
      });
    });
  }
}
