<div mat-dialog-title>
  <span>{{ isEditAction ? "Update License" : "Add License" }}</span>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<form class="license-form" [formGroup]="form" (submit)="onSubmit()" *ngIf="license || !isEditAction">
  <mat-dialog-content>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
      <mat-form-field class="width-100">
        <input required type="number" matInput placeholder="Number of accounts" formControlName="units">
      </mat-form-field>
    </div>
    <div class="flex-row space-around gap-10">
      <mat-form-field class="width-100">
        <input matInput required [matDatepicker]="sd_picker" placeholder="Valid from" formControlName="valid_from"
          (focus)="sd_picker.open()">
        <mat-datepicker-toggle matSuffix [for]="sd_picker"></mat-datepicker-toggle>
        <mat-datepicker #sd_picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="width-100">
        <input matInput required [matDatepicker]="ed_picker" placeholder="Valid until" formControlName="valid_until"
          [min]="form.value.valid_from" (focus)="ed_picker.open()">
        <mat-datepicker-toggle matSuffix [for]="ed_picker"></mat-datepicker-toggle>
        <mat-datepicker #ed_picker></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-form-field class="width-100">
      <input matInput placeholder="Description" formControlName="desc">
    </mat-form-field>
    <mat-form-field class="width-100">
      <mat-select placeholder="License Type" formControlName="type" required>
        <mat-option *ngFor="let type of (licenseTypes | keys)" [value]="type.value">{{ type.key }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-dialog-actions align="end" *ngIf="loading === false">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button color="primary" type="submit" [disabled]="!form.valid || form.pristine">{{ isEditAction ? "SAVE"
      : "CREATE"
      }}</button>
  </mat-dialog-actions>
</form>