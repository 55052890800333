import { APIService } from 'app/app.constants';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private baseUrl = APIService.Permissions;

  constructor(private http: HttpService) { }

  getAllPermissions(agencyId: string) {
    return this.http.get(`${this.baseUrl}?agency_id=${agencyId || ''}`);
  }
}
