// import { Activity } from "app/models/activity";
import { CaseActivity } from "app/models/case_activity";
import { Events } from "app/models/events";

const _t = (s, t) => `<${t}>${s}</${t}>`; // UI markup handler

export const eventsRenderer = {
    [Events.FilesCopied]: (event: CaseActivity) => {
        if (event.meta_data.folder_name) {
            if (event.meta_data.folders) {
                return `<br><span>${event.meta_data.user_name
                    } copied following folders from the case ${event.meta_data.changes[0].actual
                    }
    to the folder ${event.meta_data.folder_name} in the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${event.meta_data.folders
                        .map((folder: any, index: number) => {
                            return `${index + 1}. ${folder}`;
                        })
                        .join(`<br>`)}
    </span><br><br>`;
            } else {
                return `<br><span>${event.meta_data.user_name
                    } copied following files from the case ${event.meta_data.changes[0].actual
                    }
         to the folder ${event.meta_data.folder_name}
         in the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${Array.isArray(event.meta_data.file_name)
                        ? event.meta_data.file_name
                            .map((file: any, index: number) => {
                                return `${index + 1}. ${file}`;
                            })
                            .join(`<br>`)
                        : event.meta_data.file_name
                    }
    </span><br><br>`;
            }
        } else {
            if (event.meta_data.folders) {
                return `<br><span>${event.meta_data.user_name
                    } copied following folders from the case ${event.meta_data.changes[0].actual
                    }
         to the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${event.meta_data.folders
                        .map((folder: any, index: number) => {
                            return `${index + 1}. ${folder}`;
                        })
                        .join(`<br>`)}
    </span><br><br>`;
            } else {
                return `<br><span>${event.meta_data.user_name
                    } copied following files from the case ${event.meta_data.changes[0].actual
                    }
        to the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${Array.isArray(event.meta_data.file_name)
                        ? event.meta_data.file_name
                            .map((file: any, index: number) => {
                                return `${index + 1}. ${file}`;
                            })
                            .join(`<br>`)
                        : event.meta_data.file_name
                    }
    </span><br><br>`;
            }
        }
    },
    [Events.FileDeleted]: (event: CaseActivity) => {
        if (Array.isArray(event.meta_data.file_name)) {
            return `<br><span><br>${event.meta_data.user_name
                } deleted the following items from the case <u><i>${event.meta_data.case_name || ""
                }</i></u><br><br>
                ${event.meta_data.file_name
                    .map((file: any, index: number) => {
                        return `${index + 1}. File - ${file}`;
                    })
                    .join(`<br>`)} <br>
                ${event.meta_data.folder_name?.map((file: any, index: number) => {
                        return `${index + 1}. Folder - ${file}`;
                    })
                    .join(`<br>`)}
                <br><br></span>
            `;
        } else {
            return `<br><span><br>${event.meta_data.user_name} <b>deleted</b> the file ${event.meta_data.file_name || ''} from the case <u><i>${event.meta_data.case_name || ""
                }</i></u><br><br></span>
            `;
        }
    },
    [Events.DeletedFileRestored]: (event: CaseActivity) => {
        if (!Array.isArray(event.meta_data?.file_name)) {
            return `<p>${event.meta_data.user_name} <b>restored</b> the file ${event.meta_data.file_name || ''} from the case <u><i>${event.meta_data.case_name || ""}</i></u></p>`
        }
        return `<br><span><br>${event.meta_data.user_name
            } restored the following items from the case <u><i>${event.meta_data.case_name || ""
            }</i></u><br><br>
        ${event.meta_data.file_name
                .map((file: any, index: number) => {
                    return `${index + 1}. File - ${file}`;
                })
                .join(`<br>`)} <br>
        ${event.meta_data.folder_name?.map((file: any, index: number) => {
                    return `${index + 1}. Folder - ${file}`;
                })
                .join(`<br>`)}
          <br><br></span>
        `;
    },
    [Events.FilesMoved]: (event: CaseActivity) => {
        if (event.meta_data.folder_name) {
            if (event.meta_data.folders) {
                return `<br><span>${event.meta_data.user_name
                    } moved following folders from the case ${event.meta_data.changes[0].actual
                    }
    to the folder ${event.meta_data.folder_name} in the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${event.meta_data.folders
                        .map((folder: any, index: number) => {
                            return `${index + 1}. ${folder}`;
                        })
                        .join(`<br>`)}
    </span><br><br>`;
            } else {
                return `<br><span>${event.meta_data.user_name
                    } moved following files from the case ${event.meta_data.changes[0].actual
                    }
         to the folder ${event.meta_data.folder_name}
         in the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i><br><br>
    ${Array.isArray(event.meta_data.file_name)
                        ? event.meta_data.file_name
                            .map((file: any, index: number) => {
                                return `${index + 1}. ${file}`;
                            })
                            .join(`<br>`)
                        : event.meta_data.file_name
                    }
    </span><br><br>`;
            }
        } else {
            if (event.meta_data.folders) {
                return `<br><span>${event.meta_data.user_name
                    } moved following folders from the case ${event.meta_data.changes[0].actual
                    }
         to the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${event.meta_data.folders
                        .map((folder: any, index: number) => {
                            return `${index + 1}. ${folder}`;
                        })
                        .join(`<br>`)}
    </span><br><br>`;
            } else {
                return `<br><span>${event.meta_data.user_name
                    } moved following files from the case ${event.meta_data.changes[0].actual
                    }
        to the case <u><i>${event.meta_data.changes[0].expected
                    }</u></i> <br><br>
    ${Array.isArray(event.meta_data.file_name)
                        ? event.meta_data.file_name
                            .map((file: any, index: number) => {
                                return `${index + 1}. ${file}`;
                            })
                            .join(`<br>`)
                        : event.meta_data.file_name
                    }
    </span><br><br>`;
            }
        }
    },
    [Events.FilesSharedViaEmail]: (event: CaseActivity) => {
        if (event.meta_data.files) {
            const eventText = `<br><span>${event.meta_data.user_name} shared following files from the case <u><i>${event.meta_data.case_name || ''}</i></u> with
          ${event.meta_data.recipient} (Download id: ${event.meta_data.download_id})<br><br>
          ${event.meta_data.subject ? `Subject: ${event.meta_data.subject}<br>` : ''}
          ${event.meta_data.message ? `Message: ${event.meta_data.message}<br><br>` : ''}
          ${event.meta_data.files
                    .map((file: any, index: number) => {
                        return `${index + 1}. ${file.name}`;
                    })
                    .join(`<br>`)}</span><br><br>`;
            return eventText;
        } else {
            return `<br><span>${event.meta_data.user_name} shared files from the case <u><i>${event.meta_data.case_name || ''}</i></u>
             with ${event.meta_data.recipient} (Download id: ${event.meta_data.download_id})</span><br><br>`;
        }
    },
    [Events.FilesDownloadRequested]: (event: CaseActivity) => {
        return `<br><span>${event.meta_data.user_name} made a download request for the case <u><i>${event.meta_data.case_name || ''}</i></u> (Download id: ${event.meta_data.download_id})</span><br><br>`;
    },
    [`${Events.DeletedFileRestored}_v2`]: (event: CaseActivity) =>
        renderV2Event(event),
    [`${Events.FileDeleted}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [`${Events.FilesMoved}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [`${Events.FilesCopied}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [`${Events.FilesSharedViaEmail}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [`${Events.FilesDownloadRequested}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [`${Events.FilesSharedViaEmailDownloadRequested}_v2`]: (event: CaseActivity) => renderV2Event(event),
    [Events.FileFolderEarlyPurgeSchedule]: (event: CaseActivity) => {
        const files = event.meta_data.files;
        const folders = event.meta_data.folders;
        let itemList = ''; let countText = ``;
        if (files?.length) {
            countText += `${files.length} files`;
            itemList = `<b>Files:</b><br><br>
          ${files
                    .map((file: any, index: number) => {
                        return `${index + 1}. ${file.name} from path: ${file.path?.namedPath}`;
                    })
                    .join(`<br>`)}
          `;
        }
        if (folders?.length) {
            countText += files?.length > 0 ? ` and ${folders.length} folders` : `${folders.length} folders`;
            itemList += files?.length > 0 ? `<br><br><b>Folders:</b><br><br>
          ${folders
                    .map((folder: any, index: number) => {
                        return `${index + 1}. ${folder.root.name} ${_renderStats(folder)} from path ${folder.root.path?.namedPath}`;
                    })
                    .join(`<br>`)}
          ` : `<b>Folders:</b><br><br>
          ${folders
                .map((folder: any, index: number) => {
                    return `${index + 1}. ${folder.root.name} ${_renderStats(folder)} from path ${folder.root.path?.namedPath}`;
                })
                .join(`<br>`)}
          `;
        }
        return `<span><br>${event.meta_data.user_name} has updated ${countText} for <b>early purge</b>
            in the case <u><i>${event.meta_data.case_name || ''}</i></u><br><br>
            ${itemList}<br><br>
            </span><br><br>`
    }
};

const _getGrammaticalNumber = (word, count) => {
    let wordLookup = {
        file: ["file", "files"],
        folder: ["folder", "folders"],
    };
    const [singular, plural] = wordLookup[word];
    return count > 1 ? `${count} ${plural}` : `${count} ${singular}`;
};

const _getContentDescription = (files: any[], folders: any[]) => {
    let withContent: string = files && files.length
        ? `${_getGrammaticalNumber("file", files.length)}`
        : "";
    if (folders && folders.length)
        withContent +=
            withContent.length > 0
                ? ` and ${_getGrammaticalNumber("folder", folders.length)}`
                : `${_getGrammaticalNumber("folder", folders.length)}`;
    return withContent;
}

const _getCaseLink = (caseId, caseName): string =>
    `<span>${caseName}</span>`;
// const _getFolderLink = (folderId, folderName): string => `<a href='${location.protocol}${location.host}/app/cases/${caseId}'>${caseName}</a>`

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
function _formatBytes(x) {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l)
        n = n / 1024;
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

const _renderStats = (folder) => {
    const fileContent = folder.content && folder.content.files ? folder.content.files : []
    const folderContent = folder.content && folder.content.folders ? folder.content.folders : []
    const size = folder.size_in_bytes || null
    let stats = ` containing ${_getContentDescription(fileContent, folderContent)}`
    return size ? ` of size: ${_formatBytes(size)}` : stats
}

const renderV2Event = (event: CaseActivity) => {
    const _getEventText = () => {
        let eventText = '';
        switch (event.event_name) {
            case Events.FilesDownloadRequested:
                eventText = `created a download with`;
                if (event.meta_data.download_id) eventText = `created a download (download id - ${event.meta_data.download_id}) with`;
                break;
            case Events.FilesSharedViaEmail:
                eventText = `shared`;
                if (event.meta_data.download_id) eventText += ` (share id - ${event.meta_data.download_id})`;
                break;
            case Events.FilesSharedViaEmailDownloadRequested:
                eventText = `created a download from shared files with`;
                if (event.meta_data.download_id) eventText = `created a download (download id - ${event.meta_data.download_id}) from shared files with`;
                break;
        }
        return eventText;
    };
    let actionLookup = {
        [Events.FilesMoved]: _t("moved", "b"),
        [Events.FilesCopied]: _t("copied", "b"),
        [Events.FileDeleted]: _t("deleted", "b"),
        [Events.DeletedFileRestored]: _t("restored", "b"),
        [Events.FilesSharedViaEmail]: _t(_getEventText(), "b"),
        [Events.FilesDownloadRequested]: _t(_getEventText(), "b"),
        [Events.FilesSharedViaEmailDownloadRequested]: _t(_getEventText(), "b")
    };

    let didAction = actionLookup[event.event_name];
    const meta = event.meta_data;
    const files = meta.files || [];
    const folders = meta.folders || [];
    const receivers = meta.recipient || [];
    const fromCaseId = meta.from_case_id || meta.case_id
    const fromCaseName = meta.from_case_name || meta.case_name
    const toCaseId = meta.to_case_id || null
    let isDifferentCase = toCaseId && fromCaseId !== toCaseId;
    const isRestore = event.event_name === Events.DeletedFileRestored;

    let renderOutput = "";
    let withContent: string = _getContentDescription(files, folders)

    let inCase = isDifferentCase
        ? `from case: ${_t(
            _getCaseLink(fromCaseId, fromCaseName),
            "b"
        )} to case: ${_t(_getCaseLink(meta.to_case_id, meta.to_case_name), "b")}`
        : `in case: ${_t(_getCaseLink(fromCaseId, fromCaseName), "b")}`;
    let toPath = meta.destination_folder_path
        ? `to path: ${meta.destination_folder_path}`
        : "";

    let eventDescription = `${meta.user_name} ${didAction} ${withContent} ${inCase} ${toPath}`;

    let filesMoved = "",
        foldersMoved = "",
        emailReceivers = "";

    for (const receiver of receivers) {
        const desc = _t(receiver, 'span');
        emailReceivers += _t(desc, 'li');
    }

    for (const file of files) {
        let fromPath = ``;
        if (file.path) fromPath = _t(`${isRestore ? 'to' : 'from'} path: ${file.path ? file.path.namedPath : ''}`, "span");

        let fileDescription = _t(
            file.rename
                ? `${file.name} ${fromPath} was renamed as ${file.rename}`
                : `${file.name} ${fromPath}`,
            "span"
        );

        filesMoved += _t(fileDescription, "li");
    }

    for (const folder of folders) {
        let fromPath = ``;
        const treeRoot = folder.root
        if (treeRoot.path) fromPath = _t(`${_renderStats(folder)} ${isRestore ? 'to' : 'from'} path: ${treeRoot.path ? treeRoot.path.namedPath : ''}`, "span");

        let folderDescription = _t(
            treeRoot.rename
                ? `${treeRoot.name} ${fromPath} was renamed as ${treeRoot.rename}`
                : `${treeRoot.name} ${fromPath}`,
            "span"
        );
        foldersMoved += _t(folderDescription, "li");
    }

    if (filesMoved.length)
        filesMoved = `${_t("Files:", "h4")}${_t(filesMoved, "ol")}`;
    if (foldersMoved.length)
        foldersMoved = `${_t("Folders:", "h4")}${_t(foldersMoved, "ol")}`;
    if (emailReceivers.length)
        emailReceivers = `${_t("Shared with:", "h4")}${_t(emailReceivers, "ol")}`;

    renderOutput = _t(
        _t(eventDescription + emailReceivers + filesMoved + foldersMoved, "p"),
        "div"
    );
    return renderOutput;
};